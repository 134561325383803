import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {
  uploadScheduleRequest,
  getScheduleInfoRequest,
  getScheduleSettingsRequest,
  setScheduleSettingsRequest,
  getTeacherScheduleRequest,
  clearScheduleRequest,
} from '../api/schedule';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    snackbarState: false,
    snackbarData: null as SnackbarData,
    loadingUploadSchedule: false,
    lightboxImages: [],
    scheduleInfo: null as ScheduleInfoType,
    scheduleSettings: null as ScheduleSettingsType,
    scheduleEmployeeData: (null as unknown) as ScheduleEntry[],
    currentWeek: null,
    selectedWeek: null,
  },
  reducers: {
    setScheduleEmployee(state, action) {
      state.scheduleEmployeeData = action.payload;
    },
    setScheduleWeek(state, action) {
      state.currentWeek = action.payload;
    },
    setSelectedScheduleWeek(state, action) {
      state.selectedWeek = action.payload;
    },
    setScheduleLoading(state, action) {
      state.loadingUploadSchedule = !!action.payload;
    },
    setScheduleInfo(state, action: { payload: ScheduleInfoType }) {
      state.scheduleInfo = action.payload;
    },
    setScheduleSettings(state, action) {
      state.scheduleSettings = {
        ...(state.scheduleSettings || {}),
        ...action.payload,
      };
    },
    openSnackbar(state, action: { payload: SnackbarData }) {
      state.snackbarState = true;
      state.snackbarData = action.payload;
    },
    openLightBox(state, action) {
      state.lightboxImages = action.payload;
    },
    closeSnackbar(state) {
      state.snackbarState = false;
      state.snackbarData = null;
    },
  },
});

export const {
  openSnackbar,
  closeSnackbar,
  openLightBox,
  setScheduleInfo,
  setScheduleLoading,
  setScheduleSettings,
  setScheduleEmployee,
  setScheduleWeek,
  setSelectedScheduleWeek,
} = settingsSlice.actions;

export default settingsSlice.reducer;

export const getScheduleFromEmployeeAction = (dispatch: Dispatch) => async (
  week?: number
) => {
  try {
    const cache = JSON.parse(
      localStorage.getItem('schedulesEmployeeData') || '{}'
    );
    if (week) {
      dispatch(setSelectedScheduleWeek(week));
    }
    const cachedSchedule =
      cache[
        week ||
          Object.keys(cache)
            .map((e) => +e)
            .sort((a, b) => b - a)[0]
      ];
    dispatch(setScheduleEmployee(cachedSchedule));
    const res = await getTeacherScheduleRequest(week);
    dispatch(setScheduleEmployee(res.data.payload.schedules));
    dispatch(setScheduleWeek(res.data.payload.currentWeek));
    if (!week) {
      dispatch(setSelectedScheduleWeek(res.data.payload.selectedWeek));
    }
    (cache as any)[res.data.payload.selectedWeek] = res.data.payload.schedules;
    localStorage.setItem('schedulesEmployeeData', JSON.stringify(cache));
  } catch (err) {
    dispatch(setSelectedScheduleWeek(week));
    console.log(err);
  }
};

export const getScheduleInfoAction = (dispatch: Dispatch) => async () => {
  try {
    const res = await getScheduleInfoRequest();
    dispatch(setScheduleInfo(res.data.payload));
  } catch (err) {
    console.log(err);
  }
};

export const clearScheduleAction = (dispatch: Dispatch) => async () => {
  try {
    dispatch(setScheduleLoading(true));
    const res = await clearScheduleRequest();
    const newCount = res.data?.payload?.count || 0;
    dispatch(
      setScheduleInfo({
        updatedAt: new Date(),
        count: newCount,
      })
    );
    dispatch(
      openSnackbar({
        message: 'Расписание успешно удалено, новых записей ' + newCount,
        type: 'success',
      })
    );
    dispatch(setScheduleLoading(false));
  } catch (err) {
    dispatch(setScheduleLoading(false));
    console.log(err);
    dispatch(
      openSnackbar({
        message: 'Произошла ошибка при удалении расписаний',
        type: 'error',
      })
    );
  }
};

export const uploadScheduleAction = (dispatch: Dispatch) => async (
  file: File
) => {
  try {
    dispatch(setScheduleLoading(true));
    const res = await uploadScheduleRequest(file);
    const newCount =
      res.data?.payload?.afterCount - res.data?.payload?.beforeCount || 0;
    dispatch(
      openSnackbar({
        message: 'Расписание успешно обновлено, новых записей ' + newCount,
        type: 'success',
      })
    );
    console.log(res);
  } catch (err) {
    console.log(err);
    dispatch(
      openSnackbar({
        message: 'Произошла ошибка при обновлении расписаний',
        type: 'error',
      })
    );
  } finally {
    dispatch(setScheduleLoading(false));
  }
};

export const getScheduleSetttingsAction = (dispatch: Dispatch) => async () => {
  try {
    const { data } = await getScheduleSettingsRequest();
    dispatch(setScheduleSettings(data.payload));
  } catch (err) {}
};

export const updateScheduleSettingsAction = (dispatch: Dispatch) => async (
  model: any
) => {
  try {
    const res = await setScheduleSettingsRequest(model);
    console.log(res.data);
    dispatch(setScheduleSettings(model));
    dispatch(
      openSnackbar({
        message: 'Настройроки расписания успешно обновлены',
        type: 'success',
      })
    );
  } catch (err) {
    dispatch(
      openSnackbar({
        message: 'Произошла ошибка при обновлении настроек расписания',
        type: 'error',
      })
    );
  }
};

type SnackbarData = {
  message: string;
  type: 'error' | 'success' | 'info' | string;
} | null;

type ScheduleInfoType = {
  updatedAt: Date | string;
  count: number;
} | null;

type ScheduleSettingsType = {
  startWeekDate: Date;
} | null;

export type ScheduleEntry = {
  id: number;
  createdAt: string;
  updatedAt: string;
  isuGroupId: number;
  year: number;
  semestr: number;
  week: number;
  weekDay: number;
  timeStart: string;
  timeEnd: string;
  timeIndex: number;
  date: string;
  subgroup: number;
  type: any;
  auiditory: {
    name: string;
  };
  discip: {
    name: string;
  };
  group?: {
    NGruppa: string;
  };
};

export interface ScheduleEntryTeacher extends ScheduleEntry {
  groups: ScheduleEntry['group'][];
}
