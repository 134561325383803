import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {
  getAllQuestionsFromDeptRequest,
  createQuestionAnswerRequest,
  pinResolverToQuestionRequest
} from '../api/questions';

const questionsSlice = createSlice({
  name: 'questions',
  initialState: {
    loading: true,
    createLoading: false,
    questions: []
  },
  reducers: {
    setLoading(store, action) {
      store.loading = action.payload;
    },
    setCreateLoading(state, action) {
      state.createLoading = action.payload;
    },
    updateQuestion(state, action) {
      for (let i = 0; i < state.questions.length; i++) {
        if ((state.questions[i] as any).id === action.payload.id) {
          (state.questions[i] as any) = action.payload;
          break;
        }
      }
    },
    pinResolver(state, action) {
      state.questions.forEach((q: any) => {
        if (action.payload.id === q.id) {
          q.resolver = action.payload.data;
        }
      });
    },
    setQuestions(store, action) {
      store.loading = false;
      store.questions = action.payload;
    }
  }
});

export const {
  setQuestions,
  setLoading,
  setCreateLoading,
  updateQuestion,
  pinResolver
} = questionsSlice.actions;

export default questionsSlice.reducer;

export const getQuestionsAction = (dispatch: Dispatch) => async () => {
  try {
    dispatch(setLoading(true));
    const res = await getAllQuestionsFromDeptRequest();
    dispatch(setQuestions(res.data.payload));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createQuestionAnswerAction = (dispatch: Dispatch) => async (
  model: any
) => {
  try {
    dispatch(setCreateLoading(true));
    const { data } = await createQuestionAnswerRequest(model);
    dispatch(updateQuestion(data.payload));
    console.log(data);
  } finally {
    dispatch(setCreateLoading(false));
  }
};

export const pinResolverToQuestionAction = (dispatch: Function) => async (
  questionId: string | number
) => {
  try {
    dispatch(setCreateLoading(true));
    const res = await pinResolverToQuestionRequest(questionId);
    if (res?.data?.type === 'success') {
      dispatch(pinResolver({ id: questionId }));
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setCreateLoading(false));
  }
};
