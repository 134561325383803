import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { useModal } from '../utils';
import {
  Typography,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import Loader from '../components/Loader';
import {
  updateUserCategoryAction,
  removeUserCategoryAction,
  updateUserRole,
} from '../store/slices/user';
import { userRoleMap, userRoleTypes } from '../store/middlewares/auth';

const UserInfo: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const users = useSelector((state: RootState) => state.user.employeesForAdmin);
  const categories = useSelector(
    (state: RootState) => state.categories.categories
  );
  const loadingUserForAdmin = useSelector(
    (state: RootState) => state.user.loadingUserForAdmin
  );
  const urlParams = new URLSearchParams(location.search);
  const selectedUser: any = users.find(
    (u: any) => u.id === +(urlParams.get('u') || '')
  );
  const modal = useModal();
  if (!selectedUser) {
    modal.close();
    return null;
  }
  const changeRole = (role: userRoleTypes) =>
    updateUserRole(dispatch)(role, selectedUser.id);
  const addNewDepartament = (depId: number) => {
    updateUserCategoryAction(dispatch)(depId, selectedUser.id);
  };
  const removeDepartment = (depId: number) => {
    removeUserCategoryAction(dispatch)(depId, selectedUser.id);
  };
  const userData = selectedUser.data;
  return (
    <div className={classes.wrapper}>
      <Typography variant="subtitle2">Имя:</Typography>
      <Typography variant="body1">
        {userData.FirstName} {userData.LastName}
      </Typography>
      <Typography variant="subtitle2">Роль:</Typography>
      {/* <Typography variant="body1">{selectedUser.role}</Typography> */}
      <div className={classes.user_role_select}>
        <FormControl>
          <InputLabel>Роль пользователя</InputLabel>
          <Select
            value={selectedUser.role}
            onChange={(e: any) => changeRole(e.target.value)}
          >
            {Object.values(userRoleMap).map((r) => (
              <MenuItem key={r} value={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Typography variant="subtitle2">Департамент</Typography>
      <div className="categories">
        {selectedUser.categories.map((c: any) => (
          <Chip
            className={classes.chip}
            key={c.id}
            label={c.category}
            onDelete={() => removeDepartment(c.id)}
          />
        ))}
      </div>
      <div className={classes.select}>
        <FormControl disabled={loadingUserForAdmin}>
          <InputLabel>Добавить департамент</InputLabel>
          <Select
            value={''}
            onChange={(e: any) => addNewDepartament(e.target.value)}
          >
            {categories.map((c: any) => (
              <MenuItem key={c.id} value={c.id}>
                {c.category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {loadingUserForAdmin && (
        <div className={classes.loader}>
          <Loader />
          <Typography variant="body2" className={classes.loader_text}>
            Обновление данных
          </Typography>
        </div>
      )}
    </div>
  );
};

export default UserInfo;

const useStyles = createUseStyles({
  wrapper: {},
  user_role_select: {
    maxWidth: 180,
    '& > div': {
      width: '100%',
    },
  },
  chip: {
    marginTop: 12,
    marginRight: 6,
  },
  loader: {
    display: 'flex',
    padding: [8, 0],
    justifyContent: 'flex-end',
  },
  loader_text: {
    paddingLeft: 12,
  },
  select: {
    paddingTop: 12,
    maxWidth: 300,
    '& > div': {
      width: '100%',
    },
  },
});
