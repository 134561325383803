import React, { FC, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  TableFooter,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import Input from '../components/Input';
import Loader from '../components/Loader';
import { API_URL } from '../config';
import { rgba } from '../assets/styles';
import AvatarView from '../components/AvatarView';
import Icon from '../components/Icon';
import { DatePipe } from '../helpers/datePipe';

const PAGE_COUNT = 20;

const Students: FC = () => {
  const [searchText, setSearchText] = useState('');
  const _students = useSelector(
    (state: RootState) => state.user.studentsForAdmin
  );

  const [page, setPage] = useState(0);

  const students = useMemo(
    () =>
      _students
        .filter((s, i) => {
          const st = searchText.toLowerCase().trim() || '';
          const userData = s.data || {};
          return (
            (userData.FirstName + userData.LastName + '')
              .toLowerCase()
              .indexOf(st) >= 0 ||
            (s?.group?.NGruppa + '').toLowerCase().indexOf(st) >= 0
          );
        })
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        ),
    [_students, searchText]
  );
  const classes = useStyles();

  const handleChangePage = (event: any, newPage: number) => setPage(newPage);
  return (
    <div>
      <div className={classes.controls_wrapper}>
        <div>
          <Paper className={classes.controls_card}>
            <Input
              placeholder="Поиск по студентам"
              label="Поиск студентов"
              value={searchText}
              onChange={(e: any) => setSearchText(e.target.value)}
              meta={{}}
            />
          </Paper>
        </div>
      </div>

      <div>
        {!students && (
          <div className={classes.loader_wrapper}>
            <Loader />
            <Typography>Загрузка списка студентов</Typography>
          </div>
        )}
        {students && students.length > 0 && (
          <Paper className={classes.table_wrapper}>
            <Table className={classes.table} size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Имя</TableCell>
                  <TableCell>Группа</TableCell>
                  <TableCell>Дата регистрации</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students
                  .slice(page * PAGE_COUNT, page * PAGE_COUNT + PAGE_COUNT)
                  .map((u: any) => (
                    <TableRow key={u.id} hover>
                      <TableCell>
                        <AvatarView
                          url={API_URL + '/user/photo/' + u.id}
                          name={`${u.data.FirstName} ${u.data.LastName}`}
                        />
                      </TableCell>
                      <TableCell>
                        {u.group?.IdGruppa ? (
                          <Tooltip title={u.group?.IdGruppa}>
                            <span>{u.group?.NGruppa || '-'}</span>
                          </Tooltip>
                        ) : (
                          u.group?.NGruppa || '-'
                        )}
                      </TableCell>
                      <TableCell>
                        {new DatePipe(u.createdAt).getLongDateWithHours()}
                      </TableCell>
                      <TableCell>{u.data.EMail}</TableCell>
                      <TableCell>{u.id}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={5}
                    count={students.length}
                    rowsPerPageOptions={[]}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} из ${
                        count !== -1 ? count : `more than ${to}`
                      }`
                    }
                    labelRowsPerPage="asd"
                    rowsPerPage={PAGE_COUNT}
                    page={page}
                    onChangePage={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default Students;

const TablePaginationActions: FC<any> = ({
  count,
  page,
  rowsPerPage,
  onChangePage,
}) => {
  const handleFirstPageButtonClick = (event: any) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  const classes = useTableFooterStyles();
  return (
    <div className={classes.arrows_wrapper}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <Icon>first_page</Icon>
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <Icon>keyboard_arrow_left</Icon>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <Icon>keyboard_arrow_right</Icon>
        <Icon></Icon>
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <Icon>last_page</Icon>
        <Icon></Icon>
      </IconButton>
    </div>
  );
};

const useTableFooterStyles = createUseStyles({
  arrows_wrapper: {
    display: 'flex',
  },
});

const useStyles = createUseStyles({
  table: {
    '& thead': {
      position: 'sticky',
      top: 0,
    },
  },
  user_avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    display: 'block',
    width: 36,
    height: 36,
    borderRadius: 36 / 2,
    objectFit: 'cover',
    objectPosition: 'center',
    marginRight: 8,
    backgroundColor: rgba('#000000', 0.12),
  },
  loader_wrapper: {
    display: 'flex',
    padding: [6, 0],
    '& span': {
      marginLeft: 4,
    },
  },
  controls_wrapper: {
    marginBottom: 24,
  },
  cell_phone: {
    whiteSpace: 'nowrap',
  },
  controls_card: {
    padding: 8,
  },
  table_wrapper: {
    overflowX: 'auto',
  },
});
