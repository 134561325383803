import React, { FC, useEffect } from 'react';
import { Typography, Button } from '@material-ui/core';
import { DatePipe } from '../helpers/datePipe';
import { RootState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useModal, errorRequiredMessage } from '../utils';
import Status from '../components/Status';
import { createUseStyles } from 'react-jss';
import Input from '../components/Input';
import { useFormik } from 'formik';
import Loader from '../components/Loader';
import {
  createQuestionAnswerAction,
  pinResolverToQuestionAction,
} from '../store/slices/questions';
import * as Yup from 'yup';
import UserInfoPopup from '../components/UserInfoPopup';
import AnswerItem from '../components/AnswerItem';
import { COLORS, css, rgba } from '../assets/styles';
import { getGroupStudentAction } from '../store/slices/user';
import { openLightBox } from '../store/slices/settings';
import Icon from '../components/Icon';

const QuestionInfo: FC = () => {
  const classes = useStyles();
  const questions = useSelector(
    (state: RootState) => state.questions.questions
  );
  const createLoading = useSelector(
    (state: RootState) => state.questions.createLoading
  );
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const questionId = +(urlParams.get('q') || -1);
  const selectedQuestion: any = questions.find((q: any) => questionId === q.id);
  const myUserData = useSelector((state: RootState) => state.user.data);
  const dispatch = useDispatch();
  const additionalUsersData: any = useSelector(
    (state: RootState) => state.user.creatorsAdditionalData
  );
  const creatorId = selectedQuestion?.creator?.id;
  useEffect(() => {
    if (!additionalUsersData[creatorId]) {
      getGroupStudentAction(dispatch)(creatorId);
    }
  }, [creatorId, additionalUsersData, dispatch]);
  const pinResolver = () => {
    pinResolverToQuestionAction(dispatch)(selectedQuestion.id);
  };
  const formik = useFormik({
    initialValues: {
      answer: '',
    },
    validationSchema: Yup.object({
      answer: Yup.string().required(errorRequiredMessage),
    }),
    onSubmit: (values) => {
      if (formik.isValid) {
        console.log(values);
        formik.resetForm();
        createQuestionAnswerAction(dispatch)({
          text: values.answer,
          id: selectedQuestion.id,
        });
      }
    },
  });
  const modal = useModal();
  if (!selectedQuestion) {
    modal.close();
    return null;
  }
  const creatorData = selectedQuestion?.creator?.data;
  const resolverData = selectedQuestion?.resolver?.data;
  return (
    <div>
      <div className={classes.status_wrapper}>
        <Typography variant="subtitle2" className={classes.status_title}>
          Статус
        </Typography>
        <Status status={selectedQuestion.status} />
      </div>
      <Typography variant="subtitle2">Текст вопроса</Typography>
      <Typography variant="body1">{selectedQuestion.text}</Typography>
      {selectedQuestion.media && selectedQuestion.media.length && (
        <>
          <Typography variant="subtitle2">Прикрепленные фото</Typography>
          <div className={classes.photos}>
            {selectedQuestion.media.map((m: string, i: number) => (
              <QuestionImage
                src={m}
                key={i}
                openPhoto={() => dispatch(openLightBox(selectedQuestion.media))}
              />
            ))}
          </div>
        </>
      )}
      <Typography variant="subtitle2">Автор</Typography>
      <UserInfoPopup
        userInfo={{
          ...creatorData,
          id: selectedQuestion?.creator?.id,
          group: additionalUsersData[selectedQuestion?.creator?.id]?.group,
        }}
      >
        <Typography variant="body1">
          {creatorData
            ? creatorData.FirstName + ' ' + creatorData.LastName
            : '-'}
        </Typography>
      </UserInfoPopup>
      <Typography variant="subtitle2">Дата создания</Typography>
      <Typography variant="body1">
        {new DatePipe(
          new Date(selectedQuestion.createdAt)
        ).getLongDateWithHours()}
      </Typography>
      <Typography variant="subtitle2">Дата разрешения</Typography>
      <Typography variant="body1">
        {new Date(selectedQuestion.updatedAt).getTime() !==
        new Date(selectedQuestion.createdAt).getTime()
          ? new DatePipe(
              new Date(selectedQuestion.updatedAt)
            ).getLongDateWithHours()
          : '-'}
      </Typography>
      <Typography variant="subtitle2">Ответивший</Typography>
      <UserInfoPopup
        userInfo={{ ...resolverData, id: selectedQuestion?.resolver?.id }}
      >
        <Typography variant="body1">
          {resolverData
            ? resolverData.FirstName + ' ' + resolverData.LastName
            : '-'}
        </Typography>
      </UserInfoPopup>
      {selectedQuestion.status !== 'pending' && (
        <>
          <Typography variant="subtitle2">Оценка</Typography>
          <Typography variant="body1">
            <div className={classes.stars_wrapper}>
              {Array(selectedQuestion.rating || 0)
                .fill(0)
                .map((_, i) => (
                  <div key={i} className={classes.star_active}>
                    <Icon>star_border</Icon>
                  </div>
                ))}
              {Array(5 - (selectedQuestion.rating || 0))
                .fill(0)
                .map((_, i) => (
                  <div key={i} className={classes.star_inactive}>
                    <Icon>star_border</Icon>
                  </div>
                ))}
            </div>
            {selectedQuestion.rating === 0 ? 'Не помогло' : 'Помогло'}
          </Typography>
        </>
      )}
      {
        selectedQuestion.answers &&
          selectedQuestion.answers.length > 0 &&
          [...selectedQuestion.answers]
            .sort(
              (a: any, b: any) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
            .map((a: any, i: number) => (
              <div key={a.id + i}>
                {(selectedQuestion.answers[i + 1] &&
                  new Date(selectedQuestion.answers[i].createdAt).getDate() !==
                    new Date(
                      selectedQuestion.answers[i + 1].createdAt
                    ).getDate()) ||
                i === 0 ? (
                  <div className={classes.answer_date_wrapper}>
                    <div className={classes.answer_date}>
                      {new DatePipe(a.createdAt).getLongDate()}
                    </div>
                  </div>
                ) : null}
                <div
                  className={css(
                    classes.answer_item,
                    a.UserId === selectedQuestion?.resolver?.id
                      ? classes.answer_item_me
                      : ''
                  )}
                >
                  <AnswerItem
                    date={a.createdAt}
                    isMe={a.UserId === selectedQuestion?.resolver?.id}
                    text={a.text}
                  />
                </div>
              </div>
            ))
        // <>
        //   <Typography variant="subtitle2">Ответ</Typography>
        //   <Typography variant="body1">{selectedQuestion.answer}</Typography>
        // </>
      }

      {selectedQuestion.status === 'pending' &&
        selectedQuestion.resolver?.id === myUserData?.id && (
          <form onSubmit={formik.handleSubmit}>
            <Input
              label="Ответ"
              placeholder="Введите ответ"
              type="text"
              className={classes.answer_field}
              meta={formik.getFieldMeta('answer')}
              {...formik.getFieldProps('answer')}
              multiline
            />
            <div className={classes.footer}>
              <div className={classes.loader_wrapper}>
                {createLoading && <Loader />}
              </div>
              <Button disabled={createLoading || !formik.isValid} type="submit">
                Ответить
              </Button>
            </div>
          </form>
        )}
      {!selectedQuestion.resolver && selectedQuestion.status === 'pending' && (
        <div className={css(classes.footer)}>
          <Button disabled={createLoading} onClick={pinResolver}>
            Взять вопрос
          </Button>
        </div>
      )}
    </div>
  );
};

export default QuestionInfo;

const QuestionImage = ({ src, openPhoto }: any) => {
  const classes = useQuestionImageStyles();
  return (
    <div className={classes.wrapper} onClick={openPhoto}>
      <img src={src} alt="" className={classes.photo} />
    </div>
  );
};

const useQuestionImageStyles = createUseStyles({
  wrapper: {
    padding: 6,
    height: 200,
    minWidth: 180,
    width: '33%',
    flexGrow: 1,
  },
  photo: {
    borderRadius: 10,
    border: '1px solid rgba(0,0,0,0.12)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});

const useStyles = createUseStyles({
  stars_wrapper: {
    display: 'flex',
  },
  star_active: {
    color: '#ffd01d',
  },
  star_inactive: {
    color: rgba(COLORS.black, 0.54),
  },
  status_wrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 12,
  },
  answer_date_wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: [12, 0],
  },
  answer_date: {
    padding: [6, 10],
    borderRadius: 12,
    backgroundColor: rgba('#333', 0.7),
    fontSize: 12,
    color: '#fff',
  },
  answer_item: {
    marginTop: 12,
    display: 'flex',
  },
  answer_item_me: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  status_title: {
    paddingRight: 12,
  },
  answer_field: {
    width: '100%',
    '& textarea': {
      minHeight: 70,
    },
  },
  loader_wrapper: {
    paddingRight: 12,
  },
  footer: {
    display: 'flex',
    paddingTop: 24,
    justifyContent: 'flex-end',
  },
  photos: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: [0, -6],
  },
});
