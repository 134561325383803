import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {
  getUserDataRequest,
  getEmployeesRequest,
  addNewCategoryFromUserRequest,
  removeCategoryFromUserRequest,
  getStudentGroupRequest,
  getStudentsRequest,
  updateUserRoleRequest,
  getUsersFromPushMessagesRequest,
} from '../api/user';
import { userRoleTypes } from '../middlewares/auth';

/* UserData example
 * UserName: 'kolesnikov.el';
 * EMail: 'freethinkel@gmail.com';
 * FirstName: 'Егор';
 * LastName: 'Колесников';
 * ParentName: 'Леонидович';
 * City: 'Казань';
 * Country: 'RU';
 * Dept: 'Центр информатизации и компьютеризации';
 * Position: 'Оператор ЭВМ';
 * Cabinet: 'Д-332';
 * Phone: null;
 * IdUserType: 1;
 * CreateDate: '2017-02-20T14:48:20.233Z';
 * IdUser: 9819;
 * ConfirmCode: null;
 * AttemptCount: null;
 * LastAccess: '2020-01-31T15:34:10.855Z';
 * IdBlockReason: null;
 * IsActivated: null;
 * IdPerson: 1501;
 * PersDataProcessingAccepted: true;
 * */

const initArrayAnyType = (): any[] => [];

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    studentsAdditionsInfo: {},
    employeesForAdmin: initArrayAnyType(),
    studentsForAdmin: initArrayAnyType(),
    loadingUserForAdmin: false,
    creatorsAdditionalData: {},
    pushMessagesUsers: (null as unknown) as [],
    data: {
      id: null,
      isuId: null,
      role: 'USER',
      createdAt: null,
      updatedAt: null,
      categories: [],
    },
    isu: {
      UserName: null,
      EMail: null,
      FirstName: null,
      LastName: null,
      ParentName: null,
      City: null,
      Country: null,
      Dept: null,
      Position: null,
      Cabinet: null,
      Phone: null,
      IdUserType: null,
      CreateDate: null,
      IdUser: null,
      ConfirmCode: null,
      AttemptCount: null,
      LastAccess: null,
      IdBlockReason: null,
      IsActivated: null,
      IdPerson: null,
      PersDataProcessingAccepted: null,
    },
  },
  reducers: {
    setPMUsers(state, action) {
      state.pushMessagesUsers = action.payload || [];
    },
    setStudentInfo(state, action) {
      (state.studentsAdditionsInfo as any)[action.payload.id] = action.payload;
    },
    setEmployeesForAdmin(state, action) {
      state.employeesForAdmin = action.payload || [];
    },
    setCreatorsAdditionalData(state, action) {
      (state.creatorsAdditionalData as any)[action.payload.id] = action.payload;
    },
    setLoadingUsersForAdmin(state, action) {
      state.loadingUserForAdmin = !!action.payload;
    },
    setStudentsForAdmin(state, action) {
      state.studentsForAdmin = action.payload || [];
    },
    putEmployeeForAdmin(state, action) {
      for (let i = 0; i < state.employeesForAdmin?.length || 0; i++) {
        if (action.payload.id === (state.employeesForAdmin[i] as any).id) {
          (state.employeesForAdmin[i] as any) = action.payload;
          break;
        }
      }
    },
    setUserData(state, action) {
      Object.keys(action.payload.isu).forEach((key: string) => {
        (state.isu as any)[key] = action.payload.isu[key];
      });
      Object.keys(action.payload.user).forEach((key: string) => {
        (state.data as any)[key] = action.payload.user[key];
      });
    },
    setUserDataLoading(state, action) {
      state.loading = action.payload;
    },
    changeRole(
      state,
      action: { payload: { id: string | number; role: userRoleTypes } }
    ) {
      const user = state.employeesForAdmin.find(
        (e) => e.id === action.payload.id
      );
      user.role = action.payload.role;
    },
  },
});

export const {
  setUserData,
  setUserDataLoading,
  setEmployeesForAdmin,
  setStudentInfo,
  setStudentsForAdmin,
  setLoadingUsersForAdmin,
  putEmployeeForAdmin,
  setCreatorsAdditionalData,
  changeRole,
  setPMUsers,
} = userSlice.actions;

export default userSlice.reducer;

export const getStudentsAction = (dispatch: Dispatch) => async () => {
  try {
    const { data } = await getStudentsRequest();
    dispatch(setStudentsForAdmin(data.payload));
  } catch (e) {
    dispatch(setStudentsForAdmin([]));
    console.log(e);
  }
};

export const getUserDataAction = (dispatch: Dispatch) => async () => {
  try {
    dispatch(setUserDataLoading(true));
    const { data } = await getUserDataRequest();
    dispatch(setUserData(data.payload));
  } finally {
    dispatch(setUserDataLoading(false));
  }
};

export const getStudentInfo = (dispatch: Dispatch) => async (
  studentId: string | number
) => {};

export const getEmployeesAction = (dispatch: Dispatch) => async () => {
  try {
    const { data } = await getEmployeesRequest();
    dispatch(setEmployeesForAdmin(data.payload || []));
  } catch (err) {
    console.log(err);
  }
};

export const updateUserCategoryAction = (dispatch: Dispatch) => async (
  depId: string | number,
  userId: string | number
) => {
  try {
    dispatch(setLoadingUsersForAdmin(true));
    const { data } = await addNewCategoryFromUserRequest(+depId, +userId);
    dispatch(putEmployeeForAdmin(data.payload));
  } finally {
    dispatch(setLoadingUsersForAdmin(false));
  }
};

export const removeUserCategoryAction = (dispatch: Dispatch) => async (
  depId: string | number,
  userId: string | number
) => {
  try {
    dispatch(setLoadingUsersForAdmin(true));
    const { data } = await removeCategoryFromUserRequest(+depId, +userId);
    dispatch(putEmployeeForAdmin(data.payload));
  } finally {
    dispatch(setLoadingUsersForAdmin(false));
  }
};

export const getGroupStudentAction = (dispatch: Dispatch) => async (
  id: string | number
) => {
  const { data } = await getStudentGroupRequest(id);
  dispatch(setCreatorsAdditionalData({ id: id, group: data.payload.NGruppa }));
};

export const updateUserRole = (dispatch: Dispatch) => async (
  role: userRoleTypes,
  userId: string | number
) => {
  try {
    dispatch(setLoadingUsersForAdmin(true));
    await updateUserRoleRequest(role, userId);
    dispatch(changeRole({ role, id: userId }));
    dispatch(setLoadingUsersForAdmin(false));
  } catch (err) {
    console.log(err);
  }
};

export const getPMUsersAction = (dispatch: Dispatch) => async () => {
  const res = await getUsersFromPushMessagesRequest();
  dispatch(setPMUsers(res.data.payload));
};
