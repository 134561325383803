import React, { FC, useEffect } from 'react';
import Routes from './Routes';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './assets/styles';
import Header from './components/Header';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './store';
import Modal from './HOC/Modal';
import { createUseStyles } from 'react-jss';
import SnackbarWrapper from './components/SnackbarWrapper';
import PhotoLightboxWrapper from './HOC/PhotoLightboxWrapper';

type AppProps = {};

const App: FC<AppProps> = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'INIT_APP' });
  }, [dispatch]);
  return (
    <ThemeProvider theme={theme}>
      {!!token && <Header />}
      {/* {!!token && <NavigationBar />} */}
      <main className="page">
        <div className="container">
          <div className="row">
            <div className={classes.router_outlet}>
              <Routes />
            </div>
          </div>
        </div>
      </main>
      <Modal />
      <SnackbarWrapper />
      <PhotoLightboxWrapper />
    </ThemeProvider>
  );
};
const useStyles = createUseStyles({
  menu: {
    width: '25%',
    minWidth: 200,
    padding: [0, 8],
  },
  router_outlet: {
    flexGrow: 1,
    width: '100%',
  },
});

export default App;
