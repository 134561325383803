// import { CSSProperties } from 'react';
// import * as csstype from 'csstype';
import { createMuiTheme } from '@material-ui/core';

export const css = (...classes: (string | undefined)[]) =>
  [...classes].filter(e => !!e).join(' ');

// @ts-ignore
// type Style = csstype.StandardProperties<
//   string | number | (() => string | number)
// > & {
//   [key: string]: Style | string | number;
// };

// export type Styles = {
//   [key: csstype.StandardProperties | string]: csstype.StandardProperties
// };

export const rgba = (hex: string, opacity: number) => {
  let result: any;
  if (hex.length === 7) {
    result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) || [];
  } else {
    result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hex) || [];
  }
  result.shift();
  try {
    return `rgba(${[...result.map((el: any) => parseInt(el, 16))].join(
      ', '
    )}, ${opacity})`;
  } catch (err) {
    return `rgba(0,0,0,0)`;
  }
};

export const theme = createMuiTheme({
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: 'PT Sans',
    fontWeightBold: 900,
    fontWeightMedium: 700
  },
  palette: {
    primary: {
      light: '#4A85DD',
      main: '#4A85DD',
      dark: '#4A85DD'
    },
    secondary: {
      light: '#674ADD',
      main: '#674ADD',
      dark: '#674ADD'
    },
    error: {
      main: '#EB5757',
      light: '#EB5757',
      dark: '#EB5757'
    }
  }
});

export const COLORS = {
  primary: '#4A85DD',
  black: '#282828',
  error: '#EB5757',
  success: '#27AE60'
};
