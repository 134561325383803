import React, { FC, useState, useEffect, useMemo } from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import { createUseStyles } from 'react-jss';
import UploadFile from '../components/UploadFile';
import { DatePipe } from '../helpers/datePipe';
import { COLORS, rgba } from '../assets/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  uploadScheduleAction,
  updateScheduleSettingsAction,
  clearScheduleAction,
} from '../store/slices/settings';
import { RootState } from '../store';
import Loader from '../components/Loader';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ruLocale from 'date-fns/locale/ru';

import DateFnsUtils from '@date-io/date-fns';
import Icon from '../components/Icon';
import { userRoleMap } from '../store/middlewares/auth';

const Schedule: FC = () => {
  const classes = useStyles();
  const [startSemesterDate, setStartSemesterDate] = useState(
    (null as unknown) as Date
  );

  const startWeekDate = useSelector(
    (state: RootState) => state.settings.scheduleSettings?.startWeekDate
  );
  useEffect(() => {
    if (startWeekDate) {
      const date = new Date(startWeekDate as any);
      setStartSemesterDate(date);
    }
  }, [startWeekDate]);

  const isDateValid = useMemo(
    () =>
      ~~(new Date(startWeekDate || 0).getTime() / (1000 * 60)) !==
      ~~(startSemesterDate?.getTime() / (1000 * 60)),
    [startWeekDate, startSemesterDate]
  );

  const onSaveDate = () => {
    if (isDateValid) {
      updateScheduleSettingsAction(dispatch)({ date: startSemesterDate });
    }
  };

  const dispatch = useDispatch();
  const scheduleLoader = useSelector(
    (state: RootState) => state.settings.loadingUploadSchedule
  );
  const scheduleInfo = useSelector(
    (state: RootState) => state.settings.scheduleInfo
  );

  const userRole = useSelector((state: RootState) => state.user.data.role);
  const isAdmin = userRole === userRoleMap.ADMIN;
  return (
    <div>
      <div className="container">
        <Typography variant="h3" className={classes.title}>
          Расписание
        </Typography>
      </div>

      <div className="container">
        {!!scheduleInfo && <ScheduleInfo info={scheduleInfo} />}
        {isAdmin && (
          <div className={classes.form}>
            <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy"
                margin="normal"
                id="date-picker-inline"
                // errorM="Неверная дата"
                label="Дата начала 1ой недели"
                value={startSemesterDate}
                onChange={(date) => setStartSemesterDate(date as Date)}
                invalidDateMessage="Неверный формат даты"
                disabled={scheduleLoader}
                inputProps={{
                  disabled: true,
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
            <IconButton
              className={classes.save_btn}
              onClick={onSaveDate}
              disabled={!isDateValid || scheduleLoader}
            >
              <Icon>save</Icon>
            </IconButton>
          </div>
        )}
        <div className={classes.upload_btn_wrapper}>
          {!scheduleLoader && (
            <UploadFile
              placeholder="Выбрать файл расписания"
              accept=".json"
              onSelect={(files: FileList) =>
                uploadScheduleAction(dispatch)(files[0])
              }
            />
          )}
          <Button
            onClick={clearScheduleAction(dispatch)}
            startIcon={
              scheduleLoader ? <Loader color="inherit" /> : <Icon>delete</Icon>
            }
            className={classes.clear_schedule_btn}
          >
            Очистить все расписание
          </Button>
        </div>
        {!!scheduleLoader && (
          <div className={classes.loading}>
            <Loader />
            <Typography style={{ marginLeft: 12 }} variant="subtitle1">
              Загрузка расписаний
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default Schedule;

const useStyles = createUseStyles({
  rasp_card: {
    padding: 8,
    maxWidth: 400,
  },
  upload_btn_wrapper: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  clear_schedule_btn: {
    marginTop: [10, '!important'],
    color: [COLORS.error, '!important'],
  },
  history: {
    border: `1px solid ${rgba('#000000', 0.12)}`,
    borderRadius: '8px !important',
    '&::before': {
      display: 'none',
    },
  },
  form: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  save_btn: {
    marginLeft: [12, '!important'],
  },
  input_field: {
    width: '100%',
    maxWidth: 200,
    marginTop: 16,
    marginBottom: 8,
    '& > div': {
      width: 'inherit',
    },
    '& + &': {
      marginLeft: 12,
    },
  },
  input_field_small: {
    maxWidth: 50,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 12,
    '& span': {
      marginLeft: 12,
    },
  },
  subtitle: {
    paddingBottom: 12,
  },
  title: {
    paddingBottom: 18,
  },
});

const ScheduleInfo = ({ info }: any) => {
  return (
    <div>
      <div>
        <span>
          <b>Количество загруженных занятий: </b> {info.count}
        </span>
      </div>
      <div>
        <span>
          <b>Последнее обновлние: </b>{' '}
          {new DatePipe(info.updatedAt).getLongDateWithHours()}
        </span>
      </div>
    </div>
  );
};
