import React, { FC, useState, useEffect } from 'react';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { userRoleMap } from '../store/middlewares/auth';
import { COLORS } from '../assets/styles';

const ROUTES: RouteConfig[] = [
  {
    url: '/questions',
    title: 'Обращения',
  },
  {
    url: '/schedule',
    title: 'Расписание',
  },
  {
    url: '/students',
    title: 'Студенты',
    roles: [userRoleMap.ADMIN],
  },
  {
    url: '/employees',
    title: 'Сотрудники',
    roles: [userRoleMap.ADMIN],
  },
  {
    url: '/push-messages',
    title: 'Пуш уведомления',
    roles: [userRoleMap.ADMIN],
  },
  {
    url: '/categories',
    title: 'Подразделения',
    roles: [userRoleMap.ADMIN],
  },
  {
    url: '/schedule-settings',
    title: 'Расписание (настройки)',
    roles: [userRoleMap.ADMIN, userRoleMap.MODER_SCHEDULE],
  },
];

type RouteConfig = {
  url: string;
  title: string;
  roles?: typeof userRoleMap[keyof typeof userRoleMap][];
};

const NavigationBar: FC<any> = () => {
  const classes = useStyles();
  const history = useHistory();
  const [currentValue, setCurrentValue] = useState(0);
  const [routes, setRoutes] = useState([...ROUTES]);
  const students = useSelector(
    (state: RootState) => state.user.studentsForAdmin
  );
  const employees = useSelector(
    (state: RootState) => state.user.employeesForAdmin
  );
  const pushMessageUsers = useSelector(
    (store: RootState) => store.user.pushMessagesUsers
  );

  useEffect(() => {
    if (students && students.length > 0) {
      routes[2].title = `Студенты (${students.length})`;
      setRoutes([...routes]);
    }
  }, [students]);

  useEffect(() => {
    if (employees && employees.length > 0) {
      routes[3].title = `Сотрудники (${employees.length})`;
      setRoutes([...routes]);
    }
  }, [employees]);

  useEffect(() => {
    if (pushMessageUsers && pushMessageUsers.length) {
      routes[4].title = `Пуш уведомления (${pushMessageUsers.length})`;
      setRoutes([...routes]);
    }
  }, [pushMessageUsers]);

  const userRole = useSelector((state: RootState) => state.user.data.role);
  const filteredRoutes = routes.filter(
    (e) => !e.roles || e.roles?.some((r) => r === userRole)
  );
  useEffect(() => {
    setCurrentValue(
      filteredRoutes.map((e) => e.url).indexOf(history.location.pathname)
    );
  }, [filteredRoutes, history.location.pathname]);
  useEffect(() => {
    const unlisten = history.listen((l) => {
      setCurrentValue(filteredRoutes.map((e) => e.url).indexOf(l.pathname));
    });
    return unlisten;
  });
  return (
    <div className="container">
      <KgeuTabs
        value={currentValue}
        onChange={(e, r: number) => history.push(filteredRoutes[r].url)}
      >
        {filteredRoutes.map((r, i) => (
          <Tab key={i} label={r.title} className={classes.tab} />
        ))}
      </KgeuTabs>
    </div>
  );
};

const KgeuTabs = withStyles({
  indicator: {
    backgroundColor: 'transparent',
    height: 3,
    '& > div.indicator': {
      // maxWidth: 40,
      width: '100%',
      margin: '0 auto',
      height: '100%',
      borderRadius: '3px 3px 0 0',
      backgroundColor: COLORS.primary,
    },
  },
})((props: StyledTabsProps) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="auto"
    indicatorColor="primary"
    TabIndicatorProps={{ children: <div className="indicator" /> }}
  />
));

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

export default NavigationBar;

const useStyles = createUseStyles({
  card: {
    padding: [2, 12],
    marginTop: 12,
  },
  tab: {
    minWidth: ['auto', '!important'],
    textTransform: ['capitalize', '!important'],
    '&.Mui-selected': {
      color: COLORS.primary,
    },
  },
  indicator: {
    width: '100%',
    maxWidth: 40,
    backgroundColor: '#000',
    height: '100%',
    margin: '0 auto',
  },
});
