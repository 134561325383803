import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

type IconProps = {
  size?: number;
};

const Icon: FC<IconProps> = ({ children, size = 24 }) => {
  const classes = useStyles();
  return (
    <span className={classes.icon} style={{ fontSize: size }}>
      {children}
    </span>
  );
};

const useStyles = createUseStyles({
  icon: {
    fontFamily: 'Material Icons Outline',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 24,
    lineHeight: '1em',
    letterSpacing: 'normal',
    textTransform: 'none',
    display: 'inline-block',
    color: 'inherit',
    whiteSpace: 'nowrap',
    wordWrap: 'normal',
    direction: 'ltr',
    '-webkit-font-feature-settings': 'liga',
    '-webkit-font-smoothing': 'antialiased',
    userSelect: 'none'
  }
});

export default Icon;
