import { Store, AnyAction, Dispatch, Middleware } from 'redux';
import { RootState } from '..';
import { pinResolver } from '../slices/questions';

export default ((state: Store<RootState>) => (next: Dispatch<AnyAction>) => (
  action: any
) => {
  switch (action.type) {
    case pinResolver.type:
      next({
        type: pinResolver.type,
        payload: {
          id: action.payload.id,
          data: {
            ...state.getState().user.data,
            data: state.getState().user.isu
          }
        }
      });
      break;
    default:
      next(action);
      break;
  }
}) as Middleware;
