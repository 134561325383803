import { Middleware, Store, AnyAction, Dispatch } from 'redux';
import { RootState } from '..';
import { setToken, logout } from '../slices/auth';
import axios from 'axios';
import { getQuestionsAction } from '../slices/questions';
import {
  getUserDataAction,
  getEmployeesAction,
  getStudentsAction,
  getPMUsersAction,
} from '../slices/user';
import { getAllCategoriesAction } from '../slices/categories';
import {
  getScheduleFromEmployeeAction,
  getScheduleInfoAction,
  getScheduleSetttingsAction,
} from '../slices/settings';
(window as any)['axios'] = axios;

export default ((state: Store<RootState>) => (next: Dispatch<AnyAction>) => (
  action: any
) => {
  switch (action.type) {
    case setToken.type:
      const token = action.payload;
      if (token) {
        getUserDataAction(state.dispatch)();
        getQuestionsAction(state.dispatch)();
        getEmployeesAction(state.dispatch)();
        getAllCategoriesAction(state.dispatch)();
        getScheduleInfoAction(state.dispatch)();
        getScheduleSetttingsAction(state.dispatch)();
        getStudentsAction(state.dispatch)();
        getScheduleFromEmployeeAction(state.dispatch)();
        getPMUsersAction(state.dispatch)();
      }
      next(action);
      break;
    case logout.type:
      next(action);
      break;
    default:
      next(action);
      break;
  }
}) as Middleware;

export const userRoleMap = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  RESOLVER: 'RESOLVER',
  MODER_SCHEDULE: 'MODER_SCHEDULE',
};

export type userRoleTypes = keyof typeof userRoleMap;
