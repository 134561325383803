import { Store, Dispatch, AnyAction, Middleware } from 'redux';
import { RootState } from '..';
import axios from 'axios';
import { setToken } from '../slices/auth';
axios.interceptors.request.use((config) => {
  config.headers['x-access-token'] = localStorage.getItem('token');
  return config;
});

export default ((state: Store<RootState>) => (next: Dispatch<AnyAction>) => (
  action: any
) => {
  switch (action.type) {
    case 'INIT_APP':
      const token = state.getState().auth.token;
      if (token) {
        state.dispatch(setToken(token));
      }
      next(action);
      break;
    default:
      next(action);
      break;
  }
}) as Middleware;
