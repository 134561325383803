import React, { FC, useState, SetStateAction } from 'react';
import { createUseStyles } from 'react-jss';
import { Avatar, Typography, Menu, MenuItem } from '@material-ui/core';
import { theme } from '../assets/styles';

type AvatarProps = {
  name: string;
  photoUrl?: string;
  onLogout?: Function;
};

const UserAvatar: FC<AvatarProps> = ({ name, photoUrl, onLogout }) => {
  const [anchorEl, setAnchorEl] = useState(null) as SetStateAction<any>;

  const openMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  return (
    <>
      <button className={classes.wrapper} onClick={openMenu}>
        <Avatar className={classes.avatar} src={photoUrl} alt={name}>
          {(name || '')
            .split(' ')
            .map((s) => s[0] || '')
            .join('')
            .toUpperCase()}
        </Avatar>
        {!!name && (
          <Typography className={classes.name} variant="button">
            {name}
          </Typography>
        )}
      </button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            closeMenu();
            onLogout && onLogout();
          }}
        >
          Выйти
        </MenuItem>
      </Menu>
    </>
  );
};

const useStyles = createUseStyles({
  name: {
    paddingLeft: 12,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    outline: 'none',
  },
  avatar: {
    backgroundColor: [theme.palette.primary.main, '!important'],
  },
});

export default UserAvatar;
