import React, { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { COLORS, rgba, css } from '../assets/styles';
import { Button } from '@material-ui/core';
import Icon from './Icon';

type UploadFileProps = {
  className?: string;
  placeholder?: string;
  accept?: string;
  onSelect?: Function;
};

const UploadFile: FC<UploadFileProps> = ({
  className,
  placeholder,
  accept,
  onSelect,
}) => {
  const classes = useStyles();
  const [ref, setRef] = useState(null as any);
  const _onSelect = ({ target }: { target: HTMLInputElement }) => {
    console.log(target.files);
    if (onSelect) {
      onSelect(target.files);
    }
    target.value = '';
  };
  return (
    <label className={css(classes.wrapper, className)}>
      <input
        type="file"
        accept={accept}
        ref={(r) => setRef(r)}
        onChange={_onSelect}
      />
      <div className={classes.uploader}>
        <Button
          onClick={(e) => ref?.click()}
          color="primary"
          size="large"
          startIcon={<Icon>cloud_upload</Icon>}
        >
          {placeholder || 'Выбрать файл'}
        </Button>
      </div>
    </label>
  );
};

export default UploadFile;

const useStyles = createUseStyles({
  wrapper: {
    display: 'inline-flex',
    '& input[type=file]': {
      display: 'none',
    },
  },
  uploader: {
    width: '100%',
    '& button': {
      width: '100%',
      // borderRadius: 8,
      border: `2px dashed ${rgba(COLORS.primary, 0.32)}`,
    },
  },
});
