import React, { FC } from 'react';
import { TextField } from '@material-ui/core';

const Input: FC<any> = ({ meta, ...props }) => {
  return (
    <TextField
      {...props}
      error={!!(meta?.touched && meta?.error)}
      helperText={meta?.touched && meta?.error}
    />
  );
};

export default Input;
