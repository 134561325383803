import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import Input from '../components/Input';
import { RootState } from '../store';
import * as Yup from 'yup';
import { errorRequiredMessage } from '../utils';
import { sendPushRequest } from '../store/api/user';
import Icon from '../components/Icon';

const PushMessages: FC = () => {
  const classes = useStyles();
  const [usersState, setUsersState] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const users = useSelector((store: RootState) => store.user.pushMessagesUsers);
  const isLoading = !users?.length;
  const changeEnabled = (id: number, state: boolean) => {
    setUsersState({ ...usersState, [id]: state });
  };
  const allChecked =
    Object.values(usersState).filter((e) => e).length === users?.length;

  const onAllCheck = (state: boolean) => {
    if (state) {
      setUsersState(
        users.reduce((pv, cv: any) => ({ ...pv, [cv.id]: true }), {})
      );
    } else {
      setUsersState({});
    }
  };
  const formik = useFormik({
    initialValues: {
      title: '',
      body: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required(errorRequiredMessage),
      body: Yup.string().required(errorRequiredMessage),
    }),
    onSubmit: async (values) => {
      const { title, body } = values;
      console.log(title, body);
      sendPushRequest(title, body, {
        isAll: allChecked ? true : undefined,
        userIds: Object.keys(usersState)
          .filter((e) => !!((usersState || {}) as any)[e])
          .map((e) => +e),
      });
    },
  });
  const filteredUsers = searchValue
    ? users.filter(
        (u: any) =>
          (u?.group?.NGruppa + '')
            .toLowerCase()
            .indexOf((searchValue + '').toLowerCase()) >= 0 ||
          `${u?.data?.FirstName} ${u?.data?.LastName}`
            .toLowerCase()
            .indexOf((searchValue + '').toLowerCase()) >= 0
      )
    : users;
  const filterUsers = (e: any) => {
    e.preventDefault();
    setUsersState({});
    setSearchValue(e.target.querySelector('input').value + '');
    return false;
  };
  return (
    <div>
      <div className={classes.form_wrapper}>
        <Paper>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.form_controls_wrapper}>
              <div className={classes.form_control}>
                <Input
                  label="Заголовок"
                  placeholder="Заголовок сообщения"
                  type="text"
                  // name="title"
                  // className={classes.form_field}
                  meta={formik.getFieldMeta('title')}
                  {...formik.getFieldProps('title')}
                />
              </div>
              <div className={classes.form_control}>
                <Input
                  label="Тело"
                  placeholder="Тело сообщения"
                  type="text"
                  // name="body"
                  // className={classes.form_field}
                  meta={formik.getFieldMeta('body')}
                  {...formik.getFieldProps('body')}
                />
              </div>
            </div>
            <Button
              className={classes.form_btn}
              color="primary"
              variant="contained"
              type="submit"
            >
              Отправить
            </Button>
          </form>
        </Paper>
      </div>
      <Paper className={classes.controls_wrapper}>
        <form onSubmit={filterUsers}>
          <InputBase
            className={classes.controls_wrapper_input}
            placeholder="Введите имя или группу"
            inputProps={{ 'aria-label': 'search google maps' }}
          />
          <IconButton type="submit" aria-label="search">
            <Icon>search</Icon>
          </IconButton>
        </form>
      </Paper>
      {isLoading && (
        <div className={classes.loader}>
          <Loader />
          <Typography variant="body2" className={classes.loader_text}>
            Обновление данных
          </Typography>
        </div>
      )}
      {!isLoading && (
        <div className={classes.table_wrapper}>
          <Paper>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onAllCheck(e.target.checked)}
                      checked={allChecked}
                      indeterminate={
                        allChecked ? false : !!Object.keys(usersState).length
                      }
                    />
                  </TableCell>
                  <TableCell>Имя</TableCell>
                  <TableCell>Группа</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((u: any) => (
                  <TableRow key={u.id}>
                    <TableCell>
                      <Checkbox
                        onChange={(e) => changeEnabled(u.id, e.target.checked)}
                        disabled={isLoading}
                        checked={!!(usersState as any)[u.id]}
                      />
                    </TableCell>
                    <TableCell>
                      {u?.data?.FirstName} {u?.data?.LastName}
                    </TableCell>
                    <TableCell>{u?.group?.NGruppa || '-'}</TableCell>
                    <TableCell>{u?.data?.EMail}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      )}
    </div>
  );
};

export default PushMessages;

const useStyles = createUseStyles({
  controls_wrapper: {
    marginTop: 12,
    '& form': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  controls_wrapper_input: {
    paddingLeft: 12,
    width: '100%',
  },
  loader: {
    marginTop: 40,
    display: 'flex',
  },
  loader_text: {
    paddingLeft: 8,
  },
  wrapper: {},
  table: {
    '& td, th': {
      '&:nth-child(1)': {
        width: 60,
        maxWidth: 60,
      },
    },
  },
  table_wrapper: {
    marginTop: 40,
    '& > *': {
      overflow: 'auto',
    },
  },
  form_wrapper: {
    '& > div': {
      padding: [16, 8],
    },
    maxWidth: 450,
    '& form': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  form_control: {
    padding: [0, 8],
    '&:first-child': {
      minWidth: '38%',
      width: '38%',
    },
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
  form_controls_wrapper: {
    display: 'flex',
  },
  form_btn: {
    margin: [[12, 8, 0], '!important'],
    display: 'block',
    width: 120,
  },
});
