import axios from 'axios';
import { API_URL } from '../../config';

export const clearScheduleRequest = () => {
  return axios.delete(API_URL + '/schedule/clear');
};

export const uploadScheduleRequest = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(API_URL + '/schedule', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getScheduleInfoRequest = () => {
  return axios.get(API_URL + '/schedule/info');
};

export const getScheduleSettingsRequest = () => {
  return axios.get(API_URL + '/schedule/settings');
};

export const setScheduleSettingsRequest = (model: any) => {
  return axios.post(API_URL + '/schedule/settings', model);
};

export const getTeacherScheduleRequest = (week: number | undefined) => {
  return axios.get(API_URL + '/schedule/employee', { params: { week } });
};
