import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { loginRequest } from '../api/auth';
import { setUserData } from './user';
import { openSnackbar } from './settings';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('token') || '',
    loading: false,
  },
  reducers: {
    setToken(state, action: { payload: string }) {
      state.token = action.payload;
      localStorage.setItem('token', action.payload);
    },
    setAuthLoading(state, action: { payload: boolean }) {
      state.loading = action.payload;
    },
    logout(state) {
      state.token = '';
      localStorage.removeItem('token');
    },
  },
});

export const { setToken, setAuthLoading, logout } = authSlice.actions;

export default authSlice.reducer;

export const logoutAction = (dispatch: Dispatch) => () => {
  dispatch(logout());
};

export const loginAction = (dispatch: Dispatch) => (
  login: string,
  password: string
) => {
  console.log(login, password);
  dispatch(setAuthLoading(true));
  loginRequest(login, password)
    .then(({ data }) => {
      console.log(data);
      dispatch(setToken(data.payload.token));
      dispatch(setUserData(data.payload.userData));
    })
    .catch((err) => {
      console.log(err, err.response);
      dispatch(
        openSnackbar({
          message: 'Неправильный логин или пароль',
          type: 'error',
        })
      );
    })
    .finally(() => {
      dispatch(setAuthLoading(false));
    });
};
