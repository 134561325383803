import { IconButton, Paper } from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import { COLORS, rgba } from '../assets/styles';
import Icon from '../components/Icon';
import Loader from '../components/Loader';
import { DatePipe } from '../helpers/datePipe';
import { RootState } from '../store';
import {
  ScheduleEntry,
  ScheduleEntryTeacher,
  getScheduleFromEmployeeAction,
} from '../store/slices/settings';

type ScheduleEmployeeProps = {};

const ScheduleEmployee: FC<ScheduleEmployeeProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const schedules = useSelector(
    (store: RootState) => store.settings.scheduleEmployeeData
  );
  const currentWeek = useSelector(
    (store: RootState) => store.settings.currentWeek
  );
  const selectedWeek = useSelector(
    (store: RootState) => store.settings.selectedWeek
  );
  {
    /* const [selectedDate, setSelectedDate] = useState(new Date()); */
  }
  const today = new Date(new Date().setDate(new Date().getDate()));
  const selectedDate = new Date(
    new Date().setDate(
      new Date().getDate() + ((selectedWeek || 0) - (currentWeek || 0)) * 7
    )
  );
  const date = new Date(selectedDate);
  const isLoaded = currentWeek != null && !!schedules;
  const currentWeekSchedules = isLoaded ? getSchedulesFromWeek(schedules) : [];

  {
    /* const isHaveSchedules = isLoaded && !!currentDateSchedules.length; */
  }
  const weeks = [6, 0, 1, 2, 3, 4, 5];
  const changeWeek = (n: number) => {
    getScheduleFromEmployeeAction(dispatch)((selectedWeek || 0) + n);
  };
  console.log(currentWeekSchedules, date);
  return (
    <div className={classes.wrapper}>
      <ScheduleEmployeeHeader
        currentWeek={currentWeek || undefined}
        selectedWeek={selectedWeek || undefined}
        onChangeWeek={changeWeek}
      />
      <div className={classes.body}>
        {!isLoaded && (
          <div className={classes.loader}>
            <Loader />
            <div>Загрузка</div>
          </div>
        )}
        {/* {isLoaded && && (
          <div className={classes.no_schedules}>Нет занятий</div>
        )} */}
        {isLoaded && (
          <Paper className={classes.table_wrapper}>
            <table>
              <thead>
                <tr>
                  <th>Время</th>
                  <th>Дисциплина</th>
                  <th>Группы</th>
                  <th>Тип</th>
                  <th>Аудитория</th>
                </tr>
              </thead>
              <tbody>
                {currentWeekSchedules.map((week, i) => (
                  <React.Fragment key={i}>
                    <tr>
                      <td colSpan={5}>
                        <div className={classes.week_description_wrapper}>
                          <div
                            className={[
                              classes.week_description,
                              currentWeek === selectedWeek &&
                                i === weeks[today.getDay()] &&
                                classes.week_description_today,
                            ].join(' ')}
                          >
                            {DatePipe.weekDayShort[i]} (
                            {new DatePipe(
                              new Date(
                                new Date(date).setDate(
                                  date.getDate() + i - weeks[date.getDay()]
                                )
                              )
                            ).getDateMonthNumber()}
                            )
                          </div>
                        </div>
                      </td>
                    </tr>
                    {!week.length && (
                      <tr>
                        <td colSpan={5}>
                          <div className={classes.no_schedules}>
                            <Icon>event</Icon>
                            Занятия отсутствуют
                          </div>
                        </td>
                      </tr>
                    )}
                    {week.map((sch: ScheduleEntryTeacher, i: number) => (
                      <tr key={i}>
                        <td>
                          <div className={classes.time}>
                            <div className={classes.time_start}>
                              {sch.timeStart?.substr(0, 5)}
                            </div>
                            <div className={classes.time_end}>
                              {sch.timeEnd?.substr(0, 5)}
                            </div>
                          </div>
                        </td>
                        <td>{sch.discip?.name}</td>
                        <td>
                          <div className={classes.groups_wrapper}>
                            {sch.groups?.map((g) => (
                              <div key={g?.NGruppa} className={classes.group}>
                                {g?.NGruppa}
                              </div>
                            ))}
                          </div>
                        </td>
                        <td>
                          <div className={classes.type}>{sch.type?.name}</div>
                        </td>
                        <td>{sch.auiditory?.name}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default ScheduleEmployee;

const useStyles = createUseStyles({
  wrapper: {},
  week_description_wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  week_description: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    color: COLORS.success,
    backgroundColor: rgba(COLORS.success, 0.12),
    borderRadius: 2,
    padding: [2, 4],
    display: 'inline-block',
  },
  week_description_today: {
    color: COLORS.primary,
    backgroundColor: rgba(COLORS.primary, 0.12),
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& > div': {
      marginLeft: 12,
      fontSize: 16,
    },
  },
  type: {
    backgroundColor: rgba(COLORS.primary, 0.12),
    color: COLORS.primary,
    padding: [2, 4],
    borderRadius: 2,
    textAlign: 'center',
    fontSize: 14,
    display: 'inline-block',
  },
  no_schedules: {
    fontSize: 22,
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: rgba(COLORS.black, 0.54),
    width: '100%',
    padding: [12, 0],
    '& span': {
      marginRight: 8,
      marginBottom: 4,
    },
  },
  groups_wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: [0, -2],
  },
  time: {
    '& div': {
      padding: [2, 4],
      backgroundColor: rgba(COLORS.black, 0.12),
      textAlign: 'center',
      borderRadius: 2,
      fontSize: 13,
      fontWeight: 'bold',
    },
  },
  time_start: {},
  time_end: {
    // borderTop: `1px solid ${rgba(COLORS.primary, 0.24)}`,
    marginTop: 2,
  },
  group: {
    backgroundColor: COLORS.primary,
    color: '#fff',
    borderRadius: 6,
    margin: 2,
    padding: [4, 6],
    fontSize: 13,
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  table_wrapper: {
    width: '100%',
    padding: 8,
    overflow: 'auto',
    '& table': {
      width: '100%',
      borderCollapse: 'collapse',
      '& tr': {},
      '& td, & th': {
        padding: [4, 8],
        textAlign: 'left',
        border: `1px solid ${rgba(COLORS.black, 0.12)}`,
        verticalAlign: 'top',
        '&:nth-child(1)': {
          width: 70,
        },
        '&:nth-child(3)': {
          width: 200,
          maxWidth: 200,
        },
      },
    },
  },
  body: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: [0, -6],
  },
  schedule_weekday: {
    fontSize: 16,
    color: COLORS.primary,
    fontWeight: 'bold',
  },
  schedule_weekday__disabled: {
    color: rgba(COLORS.black, 0.54),
  },
  schedule_week: {
    border: `1px solid ${rgba(COLORS.black, 0.12)}`,
    padding: 12,
    borderRadius: 10,
  },
  schedule_week_wrapper: {
    padding: [0, 6],
    marginBottom: 12,
    width: '25%',
    '@media screen and (max-width: 1400px)': {
      width: '25%',
    },
    '@media screen and (max-width: 920px)': {
      width: '50%',
    },
    '@media screen and (max-width: 540px)': {
      width: '100%',
    },
  },
  schedule_card_wrapper: {
    paddingBottom: 12,
  },
});

const useHeaderStyles = createUseStyles({
  week: {
    display: 'flex',
  },
  year_month: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    marginBottom: 12,
    color: rgba(COLORS.black, 0.7),
  },
  week_day: {
    fontWeight: 'bold',
    fontSize: 18,
    backgroundColor: rgba(COLORS.black, 0.12),
    color: COLORS.black,
    borderRadius: 2,
    padding: [3, 6],
  },
  wrapper: {
    marginBottom: 24,
    padding: [12, 8],
    overflow: 'auto',
  },
  controls: {},
  calendar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 260,
    '@media screen and (max-width: 450px)': {
      maxWidth: '100%',
      width: '100%',
    },
  },
  current_week: {
    backgroundColor: rgba(COLORS.primary, 0.12),
    color: COLORS.primary,
  },
});

type ScheduleEmployeeHeaderProps = {
  date?: Date;
  selectedWeek?: number;
  currentWeek?: number;
  onChangeWeek?: (n: number) => void;
};

const ScheduleEmployeeHeader: FC<ScheduleEmployeeHeaderProps> = ({
  date = new Date(),
  selectedWeek,
  currentWeek,
  onChangeWeek,
}) => {
  const classes = useHeaderStyles();
  if (!currentWeek) {
    return null;
  }
  return (
    <Paper className={classes.wrapper}>
      <div className={classes.year_month}>
        {date.getFullYear()} {new DatePipe(date).getLongMonth('imn')} | Текущая
        неделя - {currentWeek}
      </div>
      <div className={classes.calendar}>
        <IconButton onClick={() => onChangeWeek && onChangeWeek(-1)}>
          <Icon>keyboard_arrow_left</Icon>
        </IconButton>
        <div
          className={[
            classes.week_day,
            currentWeek === selectedWeek && classes.current_week,
          ].join(' ')}
        >
          {selectedWeek || 0} неделя
        </div>
        <IconButton onClick={() => onChangeWeek && onChangeWeek(+1)}>
          <Icon>keyboard_arrow_right</Icon>
        </IconButton>
      </div>
      {/* <div className={classes.controls}>control1 control2</div> */}
    </Paper>
  );
};

const getSchedulesFromWeek = (
  schedules: ScheduleEntry[]
): ScheduleEntryTeacher[][] => {
  return new Array(7).fill(0).map((_, i) => {
    const weekDaySchedule = schedules.filter((e) => e.weekDay === i + 1);
    return Object.values(
      weekDaySchedule
        .map((e) => ({
          ...e,
          groups: weekDaySchedule
            .filter((f) => f.timeIndex === e.timeIndex)
            .map((s) => s.group),
        }))
        .reduce(
          (pv, cv) => ({
            ...pv,
            [cv.timeIndex]: [...((pv as any)[cv.timeIndex] || []), cv],
          }),
          {}
        )
    ).map((e: any) => e && e[0]);
  });
};
