import React, { FC } from 'react';
import { Snackbar } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { closeSnackbar } from '../store/slices/settings';
import MuiAlert from '@material-ui/lab/Alert';

const SnackbarWrapper: FC = () => {
  const open = useSelector((state: RootState) => state.settings.snackbarState);
  const dispatch = useDispatch();
  const data: any =
    useSelector((state: RootState) => state.settings.snackbarData) || {};
  return (
    <Snackbar
      open={open}
      autoHideDuration={+data.duration || 6000}
      onClose={() => dispatch(closeSnackbar())}
    >
      <Alert severity={data.type || 'info'}>{data.message}</Alert>
    </Snackbar>
  );
};

export default SnackbarWrapper;

const Alert = (props: any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};
