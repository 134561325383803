import axios from 'axios';
import { API_URL } from '../../config';

export const getAllCategoriesRequest = () => {
  return axios.get(API_URL + '/categories');
};

export const createCategoryGroupRequest = (name: string) => {
  return axios.post(API_URL + '/categories', {
    name
  });
};

export const addCategoriesToGroupRequest = (
  categoriyIds: (string | number)[],
  groupId: string | number
) => {
  return axios.put(API_URL + '/categories', {
    ids: categoriyIds || [],
    groupId
  });
};

export const removeCategoryGroupRequest = (id: number | string) => {
  return axios.delete(API_URL + '/categories/' + id);
};

// TODO: Add delete group
