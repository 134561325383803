import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { css, rgba, COLORS } from '../assets/styles';
import { statusMap } from '../utils';

type StatusType = {
  status: keyof typeof statusMap;
};

const Status: FC<StatusType> = ({ status }) => {
  const classes = useStyles();
  return (
    <div className={css(classes.wrapper, classes[status])}>
      {statusMap[status]}
    </div>
  );
};

const useStyles = createUseStyles({
  wrapper: {
    display: 'inline-block',
    textTransform: 'uppercase',
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 'bold',
    padding: [4, 6],
    color: '#fff',
    backgroundColor: rgba('#000', 0.24)
  },
  declined: {
    backgroundColor: COLORS.error
  },
  resolved: {
    backgroundColor: COLORS.success
  },
  pending: {
    backgroundColor: COLORS.primary
  }
});

export default Status;
