import { useLocation, useHistory } from 'react-router-dom';

export const phoneReg = /\d \(\d{3}\) \d{3}-\d{2}-\d{2}/;

export const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const loginReg = /^[a-zA-Z]{1,}\.[a-zA-Z]{2,}$/;

export const decline = (number: number, titles: string[]): string => {
  if (titles.length === 3) {
    let cases = [2, 0, 1, 1, 1, 2];
    number = Math.abs(number);
    if (number === 0) {
      return titles[2];
    } else if (Math.round(number) === +number) {
      if (+number < 1) {
        return titles[1];
      }
      number = Math.round(Math.abs(number));
      return titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    } else {
      return titles[1];
    }
  } else {
    return number + '';
  }
};

export const useQuery = (): URLSearchParams =>
  new URLSearchParams(useLocation().search);

export const useModal = () => {
  const history = useHistory();
  return {
    open: (key: string, params?: ModalParamsType) => {
      let _params: string = '';
      if (params) {
        _params = Object.keys(params)
          .map(key => `${key}=${params[key]}`)
          .join('&');
      }
      const newSearch = `?modal=${key}${_params ? '&' : ''}${_params}`;
      if (history.location.search !== newSearch) {
        history.push(newSearch);
      }
    },
    close: () => history.push(history.location.pathname)
  };
};

type ModalParamsType = {
  [key: string]: string | number;
};

// export const openModal = useCallback(
//   (key: string) => ,
//   [useHistory()]
// );

export const errorEmailMessage = 'Неверный формат email';
export const errorRequiredMessage = 'Обязательное поле';

export const statuses = ['pending', 'declined', 'resolved'];
export const statusMap = {
  pending: 'Ожидание',
  declined: 'Отменено',
  resolved: 'Завершено'
};
