import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { rgba, css, theme } from '../assets/styles';
import { Link } from 'react-router-dom';
import UserAvatar from './UserAvatar';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../store/slices/auth';
import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../store';
import Skeleton from '@material-ui/lab/Skeleton';
import { API_URL } from '../config';
import NavigationBar from './NavigationBar';

type HeaderProps = {};

const Header: FC<HeaderProps> = () => {
  const dispatch: Dispatch = useDispatch();
  const logout = logoutAction(dispatch);
  const classes = useStyles();
  const userData = useSelector((state: RootState) => state.user);
  return (
    <header className={classes.header}>
      <div className="container">
        <div className={css('row', classes.content)}>
          <Link to="/" className={classes.logo}>
            <img
              src={require('../assets/images/logo_qa.svg')}
              alt="Логотип QA КГЭУ"
            />
            <span>Электронная информационно-образовательная система</span>
          </Link>
          {!!userData.loading ? (
            <Skeleton width={120} />
          ) : (
            <UserAvatar
              onLogout={logout}
              photoUrl={
                userData.data.id
                  ? API_URL + '/user/photo/' + userData.data.id
                  : undefined
              }
              name={`${userData.isu.FirstName} ${userData.isu.LastName}`}
            />
          )}
        </div>
      </div>
      <div className="contaner">
        <NavigationBar />
      </div>
    </header>
  );
};

export default Header;

const useStyles = createUseStyles({
  header: {
    borderBottom: `1px solid ${rgba('#000', 0.12)}`,
    backgroundColor: '#fff',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    padding: [4, 0],
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: 17,
    fontWeight: 'bold',
    '& span': {
      paddingLeft: 16,
      maxWidth: 230,
    },
    '& img': {
      height: 65,
    },
  },
  content: {
    padding: [12, 0],
    justifyContent: 'space-between',
  },
});
