import { combineReducers } from 'redux';
import auth, { logout } from './slices/auth';
import { configureStore } from '@reduxjs/toolkit';
import categories from './slices/categories';
import questions from './slices/questions';
import user from './slices/user';
import settings from './slices/settings';
import authM from './middlewares/auth';
import baseM from './middlewares/base';
import questionsM from './middlewares/questions';

export const reducers = combineReducers({
  auth,
  categories,
  questions,
  user,
  settings
});

export type RootState = ReturnType<typeof reducers>;

export default configureStore({
  reducer: (state, action) => {
    if (action.type === logout.type) {
      state = undefined;
    }
    return reducers(state, action);
  },
  middleware: [baseM, authM, questionsM]
});
