import React, { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Icon from './Icon';
import { rgba, COLORS } from '../assets/styles';
import { Popover } from '@material-ui/core';

type AvatarViewProps = {
  name?: string;
  url: string;
};

const AvatarView: FC<AvatarViewProps> = ({ url, name }) => {
  const classes = useStyles();
  const [isLoaded, setLoaded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null as null | Element);
  const open: boolean = Boolean(anchorEl);
  return (
    <div
      className={classes.wrapper}
      onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
      onMouseLeave={() => setAnchorEl(null)}
    >
      <div className={classes.avatar_wrapper}>
        <img
          style={{ display: isLoaded ? '' : 'none' }}
          onLoad={() => setLoaded(true)}
          src={url}
          alt={'Фото ' + (name || 'пользователя')}
        />
        {!isLoaded && <Icon>account_circle</Icon>}
      </div>
      {!!name && <div className={classes.name}>{name}</div>}
      {isLoaded && (
        <Popover
          anchorEl={anchorEl}
          open={open}
          className={classes.popup}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          <div className={classes.popup_img_wrapper}>
            <img
              className={classes.popup_img}
              src={url}
              alt={'Фото ' + (name || 'пользователя')}
            />
          </div>
        </Popover>
      )}
    </div>
  );
};

export default AvatarView;

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  popup: {
    pointerEvents: 'none',
  },
  popup_img_wrapper: {
    height: 180,
    width: 180,
  },
  popup_img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  avatar_wrapper: {
    width: 36,
    minWidth: 36,
    height: 36,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: rgba('#000000', 0.12),
    color: COLORS.primary,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      borderRadius: 100,
    },
  },
  name: {
    marginLeft: 8,
    fontSize: 14,
    fontWeight: 'bold',
  },
  icon: {},
});
