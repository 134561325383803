import axios from 'axios';
import { API_URL } from '../../config';

export const loginRequest = (login: string, password: string) => {
  return axios.get(API_URL + '/auth/employee', {
    params: {
      login,
      password
    }
  });
};
