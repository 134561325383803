import React, { FC, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from '@material-ui/core';
import { DatePipe } from '../helpers/datePipe';
import { createUseStyles } from 'react-jss';
import { useModal, statuses, statusMap } from '../utils';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import Loader from '../components/Loader';
import Status from '../components/Status';
import { userRoleMap } from '../store/middlewares/auth';

type QuestionsProps = {};

const Questions: FC<QuestionsProps> = () => {
  const classes = useStyles();
  const modal = useModal();
  const [currentStatus, setStatus] = useState('all');
  const [filteredDepartment, setFilteredDepartment] = useState('all');
  const loading = useSelector((state: RootState) => state.questions.loading);
  const userCategories = useSelector(
    (state: RootState) => state.user.data.categories
  );
  const userRole = useSelector((state: RootState) => state.user.data.role);
  const questions =
    useSelector((state: RootState) => state.questions.questions) || [];
  const getShortText = (s: string, len: number = 100): string => {
    if (s.length < len) {
      return s;
    } else {
      return s.substr(0, len) + '...';
    }
  };
  const departamentMap = (
    useSelector((state: RootState) => state.categories.categories) || []
  ).reduce((v, cv) => ({ ...v, [cv.id]: cv.category }), {}) as any;
  const isAdmin = userRole === userRoleMap.ADMIN;
  const filteredQuestions = questions
    .filter(
      (q: any) =>
        (currentStatus === 'all' ? true : q.status === currentStatus) &&
        (userRole !== userRoleMap.ADMIN
          ? true
          : filteredDepartment === 'all' ||
            q.category?.id === +filteredDepartment)
    )
    .sort((qa: any, qb: any) => {
      let dateA = new Date(
        qa.answers
          .slice()
          .sort(
            (aa: any, ab: any) =>
              new Date(ab.createdAt).getTime() -
              new Date(aa.createdAt).getTime()
          )[0]?.createdAt || qa.updatedAt
      );
      let dateB = new Date(
        (qb.answers
          .slice()
          .sort(
            (aa: any, ab: any) =>
              new Date(ab.createdAt).getTime() -
              new Date(aa.createdAt).getTime()
          ) || [])[0]?.createdAt || qb.updatedAt
      );
      return dateB.getTime() - dateA.getTime();
    });
  const getLastChanges = (q: any) => {
    return new Date(
      (q.answers
        .slice()
        .sort(
          (aa: any, ab: any) =>
            new Date(ab.createdAt).getTime() - new Date(aa.createdAt).getTime()
        ) || [])[0]?.createdAt || q.updatedAt
    );
  };
  return (
    <div className={classes.wrapper}>
      {!loading && userCategories?.length === 0 && (
        <div className={classes.message_wrapper}>
          <Typography
            className={classes.loader_text}
            color="error"
            variant="body2"
          >
            Не удалось распознать ваше подразделение, обратитесь к
            администратору
          </Typography>
        </div>
      )}
      {userRole !== userRoleMap.ADMIN && userCategories?.length > 0 && (
        <div className={classes.title_wrapper}>
          {userCategories.map((c: any, i: number) => (
            <Typography key={i} variant="h4">
              {c.category}
            </Typography>
          ))}
        </div>
      )}
      <div className={classes.controls_wrapper}>
        <Paper className={classes.controls_card}>
          <FormControl className={classes.status_select}>
            <InputLabel>Выберите статус</InputLabel>
            <Select
              value={currentStatus}
              onChange={(e: any) => setStatus(e?.target?.value || '')}
            >
              {['all', ...statuses].map((s: any, i: number) => (
                <MenuItem value={s} key={i}>
                  {s === 'all' ? 'Все' : (statusMap as any)[s]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {userRole === userRoleMap.ADMIN && (
            <FormControl className={classes.departament_select}>
              <InputLabel>Выберите подразделение</InputLabel>
              <Select
                value={filteredDepartment}
                onChange={(e: any) =>
                  setFilteredDepartment(e?.target?.value || '')
                }
              >
                {[
                  'all',
                  ...Object.keys(
                    questions
                      .map((e: any) => e.category?.id)
                      .filter((e) => !!e)
                      .reduce((v, cv) => ({ ...v, [cv]: true }), {})
                  ),
                ].map((s: any, i: number) => (
                  <MenuItem value={s} key={i}>
                    {s === 'all' ? 'Все' : departamentMap[s]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Paper>
      </div>
      {!!loading && (
        <div className={classes.message_wrapper}>
          <Loader />
          <Typography className={classes.loader_text} variant="body2">
            Загрузка обращений
          </Typography>
        </div>
      )}
      {!loading && (!questions || questions.length === 0) && (
        <div className={classes.message_wrapper}>
          <Typography className={classes.loader_text} variant="body2">
            Нет вопросов для вашего департамента
          </Typography>
        </div>
      )}
      {!loading &&
        questions &&
        questions.length > 0 &&
        filteredQuestions?.length === 0 && (
          <div className={classes.message_wrapper}>
            <Typography className={classes.loader_text} variant="body2">
              Нет подходящих вопросов
            </Typography>
          </div>
        )}
      {!loading && filteredQuestions && filteredQuestions.length > 0 && (
        <Paper className={classes.table_wrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.table_col_wrap}>
                  Текст обращения
                </TableCell>
                <TableCell className={classes.table_col_wrap}>
                  Создатель
                </TableCell>
                {isAdmin && (
                  <TableCell className={classes.table_col_wrap}>
                    Подразделение
                  </TableCell>
                )}
                <TableCell>Дата создания</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell>Дата посл. изменения</TableCell>
                <TableCell>Дата исполнения</TableCell>
                <TableCell>Исполнитель</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredQuestions.map((q: any, i: number) => (
                <TableRow
                  key={q.id + '_' + i}
                  hover
                  className={classes.row}
                  onClick={() => modal.open('question-info', { q: q.id })}
                >
                  <TableCell className={classes.table_col_wrap}>
                    {getShortText(q.text, 90)}
                  </TableCell>
                  <TableCell className={classes.table_col_wrap}>
                    {(q?.creator?.data || {}).FirstName}{' '}
                    {(q?.creator?.data || {}).LastName}
                  </TableCell>
                  {isAdmin && (
                    <TableCell className={classes.table_col_wrap}>
                      {q?.category?.category || '-'}
                    </TableCell>
                  )}
                  <TableCell>
                    {new DatePipe(q.createdAt).getLongDateWithHours()}
                  </TableCell>
                  <TableCell>
                    <Status status={q.status} />
                  </TableCell>
                  <TableCell>
                    {new DatePipe(getLastChanges(q)).getLongDateWithHours()}
                    {/* {new DatePipe(getLastChanges(q)).getDateMonthNumber()}{' '}
                    {new DatePipe(getLastChanges(q)).getTime()} */}
                  </TableCell>
                  <TableCell>
                    {~(new Date(q.createdAt).getTime() / 1000) !==
                      ~(new Date(q.updatedAt).getTime() / 1000) &&
                    q.status !== 'pending'
                      ? new DatePipe(q.updatedAt).getLongDateWithHours()
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {(q?.resolver?.data || {}).FirstName}{' '}
                    {(q?.resolver?.data || {}).LastName}
                    {!(
                      (q?.resolver?.data || {}).FirstName &&
                      (q?.resolver?.data || {}).LastName
                    ) && '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  );
};

export default Questions;

const useStyles = createUseStyles({
  wrapper: {},
  controls_wrapper: {
    marginBottom: 24,
  },
  status_select: {
    minWidth: [120, '!important'],
  },
  departament_select: {
    minWidth: [180, '!important'],
    maxWidth: [400, '!important'],
    marginLeft: [12, '!important'],
  },
  controls_card: {
    padding: 8,
  },
  loader_text: {
    paddingLeft: 12,
  },
  title_wrapper: {
    paddingBottom: 24,
  },
  message_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: [12, 0],
  },
  table_wrapper: {
    overflowX: 'auto',
  },
  table: {
    minWidth: 850,
    '& td, & th': {
      whiteSpace: 'nowrap',
      // '&:first-child': {
      //   whiteSpace: 'normal',
      //   maxWidth: 400,
      // },
      // '&:nth-child(2)': {
      //   width: 80,
      //   whiteSpace: 'normal',
      // },
      // '&:nth-child(3)': {
      //   maxWidth: 300,
      //   whiteSpace: 'normal',
      // },
    },
  },
  table_col_wrap: {
    whiteSpace: ['normal', '!important'],
    // maxWidth: 200,
  },
  row: {
    cursor: 'pointer',
  },
});
