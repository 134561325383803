import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { css, rgba, COLORS } from '../assets/styles';
import { DatePipe } from '../helpers/datePipe';

const AnswerItem: FC<any> = ({ isMe, text, date }) => {
  const classes = useStyles();
  return (
    <div className={css(classes.wrapper, isMe && classes.isme)}>
      <span className={classes.text}>{text}</span>
      <span className={css(classes.date, isMe && classes.isme_date)}>
        {new DatePipe(date).getHours()}:{new DatePipe(date).getMinutes()}
      </span>
    </div>
  );
};

export default AnswerItem;

const useStyles = createUseStyles({
  wrapper: {
    padding: [8, 12],
    backgroundColor: rgba(COLORS.primary, 0.24),
    color: COLORS.primary,
    borderRadius: [12, 12, 12, 0],
    maxWidth: '60%',
    display: 'flex',
    flexDirection: 'column'
  },
  isme: {
    backgroundColor: rgba(COLORS.primary, 0.9),
    color: '#fff',
    borderRadius: [12, 12, 0, 12]
  },
  text: {
    fontSize: 15
  },
  date: {
    fontSize: 11,
    color: rgba(COLORS.primary, 0.7)
  },
  isme_date: {
    color: rgba('#ffffff', 0.7)
  }
});
