import React, { FC } from 'react';
import { CircularProgress } from '@material-ui/core';

type LoaderProps = {
  color?: 'primary' | 'secondary' | 'inherit';
  disableShrink?: boolean;
  size?: number | string;
  value?: number;
  className?: string;
  // TODO: Добавить поддержку сообщений во время загрузки
  // children?: ReactElement | string;
  variant?: 'determinate' | 'indeterminate' | 'static';
};

const Loader: FC<LoaderProps> = ({ variant, size, className, color }) => {
  return (
    <CircularProgress
      color={color}
      className={className}
      size={size || 20}
      thickness={5}
      variant={variant || 'indeterminate'}
    />
  );
};

export default Loader;
