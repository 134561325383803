import React, { FC, useEffect, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import QuestionInfo from '../modals/QuestionInfo';
import { useQuery, useModal } from '../utils';
import { rgba, css } from '../assets/styles';
import { Card, IconButton, Typography } from '@material-ui/core';
import Icon from '../components/Icon';
import UserInfo from '../modals/UserInfo';

type ModalProps = {};

const modalPages: any = {
  'question-info': {
    title: 'Информация о вопросе',
    component: <QuestionInfo />,
  },
  'user-info': {
    title: 'Информация о сотруднике',
    component: <UserInfo />,
  },
};

const Modal: FC<ModalProps> = () => {
  const classes = useStyles();
  const modalKey = useQuery().get('modal') || '';
  const modal = useModal();
  const closeModal = useCallback(modal.close, []);

  const content = modalPages[modalKey];
  useEffect(() => {
    if (modalKey) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    if (!content) {
      closeModal();
    }
  }, [modalKey, content, closeModal]);
  if (!content) {
    return null;
  }
  return (
    <div className={classes.wrapper}>
      <div className={css('container', classes.container_override)}>
        <div className={classes.center}>
          <Card className={classes.card}>
            <ModalHeader onClose={modal.close}>{content.title}</ModalHeader>
            <div className={classes.content}>{content.component}</div>
          </Card>
        </div>
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: rgba('#000', 0.24),
  },
  container_override: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    padding: [12, 16],
  },
  card: {},
  center: {
    margin: 'auto',
    paddingTop: 24,
    paddingBottom: 24,
    maxWidth: 600,
    width: '100%',
  },
});

const useStylesHeader = createUseStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    borderBottom: `1px solid ${rgba('#000', 0.12)}`,
    height: 54,
    padding: [0, 16],
  },
});

type ModalHeaderProps = {
  onClose?: () => void;
};

const ModalHeader: FC<ModalHeaderProps> = ({ children, onClose }) => {
  const classes = useStylesHeader();
  return (
    <div className={classes.header}>
      <Typography variant="h6">{children}</Typography>
      <IconButton onClick={onClose}>
        <Icon size={22}>close</Icon>
      </IconButton>
    </div>
  );
};

export default Modal;
