import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Card, Button, Typography } from '@material-ui/core';
import Input from '../components/Input';
import { errorRequiredMessage, loginReg } from '../utils';
import { loginAction } from '../store/slices/auth';
import Loader from '../components/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface FormValues {
  login: string;
  password: string;
}

interface AuthProps {}

const Auth: FC<AuthProps> = () => {
  const classes = useStyles();
  const loading = useSelector((state: RootState) => state.auth.loading);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: Yup.object({
      login: Yup.string()
        .matches(loginReg, 'Неправильный формат логина')
        .required(errorRequiredMessage),
      password: Yup.string().required(errorRequiredMessage),
    }),
    onSubmit: (values: AuthFormValues & FormValues) => {
      const { login, password } = values;
      loginAction(dispatch)(login, password);
    },
  });
  return (
    <div className={classes.wrapper}>
      <div className={classes.logo_wrapper}>
        <img src={require('../assets/images/logo.svg')} alt="" />
        <Typography className={classes.logo_text} color="primary" variant="h4">
          Вопросы КГЭУ
        </Typography>
      </div>
      <Card className={classes.card} elevation={18}>
        <Typography variant="h6">Авторизация</Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Input
            label="Логин"
            placeholder="Введите логин"
            type="text"
            className={classes.form_field}
            meta={formik.getFieldMeta('login')}
            {...formik.getFieldProps('login')}
          />
          <Input
            label="Пароль"
            placeholder="Введите пароль"
            type="password"
            meta={formik.getFieldMeta('password')}
            {...formik.getFieldProps('password')}
            className={classes.form_field}
          />
          <div className={classes.footer}>
            <Button
              disabled={!formik.isValid || loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              Войти
            </Button>
            {loading && <Loader className={classes.loader} />}
          </div>
        </form>
      </Card>
    </div>
  );
};

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  loader: {
    marginLeft: 12,
  },
  card: {
    maxWidth: 400,
    width: '100%',
    padding: 12,
    margin: [0, 'auto'],
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  form_field: {
    marginTop: [12, '!important'],
  },
  logo_wrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 18,
    '& img': {
      height: 60,
      paddingRight: 16,
    },
  },
  logo_text: {
    fontWeight: ['bold', '!important'],
    fontFamily: ['PT Sans Narrow', '!important'],
  },
});

export default Auth;

type AuthFormValues = {
  login: string;
  password: string;
};
