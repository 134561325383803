import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Auth from './HOC/Auth';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import Questions from './HOC/Questions';
import Categories from './HOC/Categories';
import Employees from './HOC/Employees';
import Schedule from './HOC/Schedule';
import Students from './HOC/Students';
import ScheduleEmployee from './HOC/ScheduleEmployee';
import PushMessages from './HOC/PushMessages';

type RoutesProps = {};

const Routes: FC<RoutesProps> = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  console.log(token);
  return (
    <Switch>
      <Route exact path="/">
        {token ? <Redirect to="/questions" /> : <Redirect to="/auth" />}
      </Route>
      <Route path="/auth">
        {token ? <Redirect to="/questions" /> : <Auth />}
      </Route>
      <Route path="/questions">
        {!token ? <Redirect to="/auth" /> : <Questions />}
      </Route>
      <Route path="/employees">
        {!token ? <Redirect to="/auth" /> : <Employees />}
      </Route>
      <Route path="/students">
        {!token ? <Redirect to="/auth" /> : <Students />}
      </Route>
      <Route path="/categories">
        {!token ? <Redirect to="/auth" /> : <Categories />}
      </Route>
      <Route path="/schedule-settings">
        {!token ? <Redirect to="/auth" /> : <Schedule />}
      </Route>
      <Route path="/schedule">
        {!token ? <Redirect to="/auth" /> : <ScheduleEmployee />}
      </Route>
      <Route path="/push-messages">
        {!token ? <Redirect to="/auth" /> : <PushMessages />}
      </Route>
      <Route>
        {/* TODO: implement 404 page */}
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
