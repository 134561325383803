import React, { FC, useState } from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
} from '@material-ui/core';
import { createUseStyles } from 'react-jss';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { useModal } from '../utils';
import Input from '../components/Input';
import AvatarView from '../components/AvatarView';
import { API_URL } from '../config';

const Employees: FC = () => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const modal = useModal();
  let users =
    useSelector((state: RootState) => state.user.employeesForAdmin) || [];
  users = users.filter((u: any) => {
    const st = searchText.toLowerCase().trim() || '';
    const userData = u.data || {};
    return (
      (userData.FirstName + userData.LastName + '').toLowerCase().indexOf(st) >=
        0 || (userData.Cabinet + '').toLowerCase().indexOf(st) >= 0
    );
  });
  return (
    <>
      <div className={classes.controls_wrapper}>
        <div>
          <Paper className={classes.controls_card}>
            <Input
              placeholder="Поиск по сотрудникам"
              label="Поиск сотрудников"
              value={searchText}
              onChange={(e: any) => setSearchText(e.target.value)}
              meta={{}}
            />
          </Paper>
        </div>
      </div>
      <div>
        <Paper className={classes.table_wrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Имя</TableCell>
                <TableCell>Должность</TableCell>
                <TableCell>Кабинет</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((u: any) => (
                <TableRow
                  key={u.id}
                  hover
                  onClick={() => modal.open('user-info', { u: u.id })}
                >
                  <TableCell>
                    <AvatarView
                      url={API_URL + '/user/photo/' + u.id}
                      name={`${u.data.FirstName} ${u.data.LastName}`}
                    />
                  </TableCell>
                  <TableCell>
                    {u.categories.map((c: any) => c.category).join('; ')}
                  </TableCell>
                  <TableCell>{u.data.Cabinet}</TableCell>
                  <TableCell className={classes.cell_phone}>
                    {u.data.Phone || '-'}
                  </TableCell>
                  <TableCell>{u.data.EMail}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </>
  );
};

export default Employees;

const useStyles = createUseStyles({
  table: {},
  controls_wrapper: {
    marginBottom: 24,
  },
  cell_phone: {
    whiteSpace: 'nowrap',
  },
  controls_card: {
    padding: 8,
  },
  table_wrapper: {
    overflowX: 'auto',
  },
});
