import React, { FC, useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { rgba, css } from '../assets/styles';
import { IconButton } from '@material-ui/core';
import Icon from './Icon';

type PhotoLightboxType = {
  images: string[];
  onClose: any;
  isBodyFreez?: boolean;
};

const PhotoLightbox: FC<PhotoLightboxType> = ({
  images,
  onClose,
  isBodyFreez,
}) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (isBodyFreez) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [isBodyFreez]);
  const setNext = () => setIndex((index + 1) % images.length);
  const setPrev = () =>
    setIndex(index - 1 === -1 ? images.length - 1 : index - 1);
  return (
    <div
      ref={(f) => f?.focus()}
      className={classes.wrapper}
      tabIndex={1}
      onKeyDown={(e) =>
        e.keyCode === 37
          ? setPrev()
          : e.keyCode === 39
          ? setNext()
          : e.keyCode === 27
          ? onClose()
          : null
      }
    >
      <div className={classes.center}>
        <img className={classes.img} src={images[index]} alt="" />
      </div>
      <IconButton
        className={css(classes.arrow_btn, classes.arrow_btn_left)}
        onClick={setPrev}
      >
        <Icon>keyboard_arrow_left</Icon>
      </IconButton>
      <IconButton
        className={css(classes.arrow_btn, classes.arrow_btn_right)}
        onClick={setNext}
      >
        <Icon>keyboard_arrow_right</Icon>
      </IconButton>

      <div className={classes.length}>
        {index + 1}/{images.length}
      </div>
      <IconButton className={classes.close_btn} onClick={onClose}>
        <Icon>close</Icon>
      </IconButton>
    </div>
  );
};

export default PhotoLightbox;

const useStyles = createUseStyles({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: rgba('#000', 0.32),
  },
  close_btn: {
    position: ['fixed', '!important'],
    backgroundColor: [rgba('#ffffff', 0.32), '!important'],
    top: 20,
    right: 20,
  },
  length: {
    position: 'fixed',
    bottom: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: rgba('#ffffff', 0.54),
    padding: [4, 8],
    borderRadius: 6,
    fontSize: 13,
    fontWeight: 'bold',
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  center: {
    maxWidth: 800,
    padding: [0, 50],
  },
  arrow_btn_right: {
    right: 40,
    '@media screen and (max-width: 800px)': {
      right: 20,
    },
  },
  arrow_btn_left: {
    left: 40,
    '@media screen and (max-width: 800px)': {
      left: 20,
    },
  },
  arrow_btn: {
    position: 'fixed !important',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: [rgba('#ffffff', 0.32), '!important'],
  },
});
