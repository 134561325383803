import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { rgba, css } from '../assets/styles';
import { API_URL } from '../config';

const UserInfoPopup: FC<any> = ({ userInfo, children }: any) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.children}>{children}</div>
      {userInfo && (
        <div className={css(classes.popup, 'popup')}>
          <div className={classes.text}>
            {!!userInfo.id && (
              <img
                className={classes.user_photo}
                src={API_URL + '/user/photo/' + userInfo.id}
                alt=""
              />
            )}
            <b>Должность:</b> {userInfo?.Position}
            <br />
            {!!userInfo?.group && (
              <>
                <b>Группа:</b> {userInfo?.group}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserInfoPopup;

const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
    display: 'flex',
  },
  text: {
    fontSize: 13,
  },
  user_photo: {
    height: 32,
    width: 32,
    borderRadius: 32,
    border: [2, 'solid', rgba('#333', 0.12)],
    objectFit: 'cover',
    objectPosition: 'center',
    backgroundColor: '#eee',
    display: 'block',
    marginBottom: 12,
  },
  children: {
    cursor: 'pointer',
    '&:hover + .popup': {
      opacity: 1,
      marginTop: 8,
      visibility: 'visible',
    },
  },
  popup: {
    cursor: 'default',
    top: '100%',
    minWidth: 200,
    marginTop: 0,
    position: 'absolute',
    padding: 12,
    borderRadius: 10,
    backgroundColor: rgba('#000', 0.92),
    zIndex: 10,
    opacity: 0,
    transition: '0.3s',
    visibility: 'hidden',
    boxShadow: `0 4px 8px 0 ${rgba('#000', 0.32)}`,
    '& *': {
      color: '#fff',
    },
    '&:hover': {
      opacity: 1,
      marginTop: 8,
      visibility: 'visible',
    },
  },
});
