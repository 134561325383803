import axios from 'axios';
import { API_URL } from '../../config';
export const getAllQuestionsFromDeptRequest = () => {
  return axios.get(API_URL + '/questions/employee');
};

export const createQuestionAnswerRequest = ({
  text,
  id,
}: {
  text: string;
  id: string | number;
}) => {
  return axios.post(API_URL + '/questions/answer', { answer: text, id });
};

export const pinResolverToQuestionRequest = (questionId: number | string) => {
  return axios.post(API_URL + '/questions/pin-resolver', {
    id: questionId,
  });
};
