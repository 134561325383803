import React, { FC } from 'react';

import PhotoLightbox from '../components/PhotoLightbox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { openLightBox } from '../store/slices/settings';

const PhotoLightboxWrapper: FC = () => {
  const images = useSelector(
    (state: RootState) => state.settings.lightboxImages
  );
  const dispatch = useDispatch();
  return images.length > 0 ? (
    <PhotoLightbox images={images} onClose={() => dispatch(openLightBox([]))} />
  ) : null;
};

export default PhotoLightboxWrapper;
