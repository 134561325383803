type RusMonthType = {
  rdt: string[];
  imn: string[];
};

export class DatePipe {
  d: Date;
  static rusMonth: RusMonthType = {
    rdt: [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ],
    imn: [
      'январь',
      'февраль',
      'март',
      'апрель',
      'май',
      'июнь',
      'июль',
      'август',
      'сентябрь',
      'октябрь',
      'ноябрь',
      'декабрь',
    ],
  };
  static weekDayShort = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
  static weekDay = [
    'Понелельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
  ];

  constructor(d?: Date) {
    this.d = new Date(d || new Date());
  }

  getDateMonthNumber() {
    return `${this.fixedNumber(this.d.getDate())}.${this.fixedNumber(
      this.d.getMonth() + 1
    )}.${this.getYear()}`;
  }

  getTime() {
    return `${this.getHours()}:${this.getMinutes()}`;
  }

  getYear(l: number = 4) {
    return this.fixedNumber(this.d.getFullYear(), l);
  }

  getLongMonth(t: keyof RusMonthType = 'rdt') {
    return DatePipe.rusMonth[t][this.d.getMonth()];
  }

  getLongDateWithHours() {
    return `${this.getLongDate()} ${this.getHours()}:${this.getMinutes()}`;
  }

  getHours() {
    return this.fixedNumber(this.d.getHours());
  }

  getMinutes() {
    return this.fixedNumber(this.d.getMinutes());
  }

  getLongDate() {
    return `${this.getDateMonth()} ${this.getYear()} г.`;
  }

  getDateMonth() {
    return `${this.fixedNumber(this.d.getDate())} ${this.getLongMonth()}`;
  }

  private fixedNumber(n: number | string, l: number = 2) {
    return `${(new Array(l).fill(0).join('') + n).slice(-l)}`;
  }

  static compareFromDay(a: Date, b: Date): boolean {
    return (
      ~~(a.getTime() / (1000 * 60 * 60 * 24)) ===
      ~~(b.getTime() / (1000 * 60 * 60 * 24))
    );
    // return (
    //   a.getFullYear() === b.getFullYear() &&
    //   a.getMonth() === b.getMonth() &&
    //   a.getDate() === b.getDate()
    // );
  }
}
