import axios from 'axios';
import { API_URL } from '../../config';
import { userRoleTypes } from '../middlewares/auth';

export const getUserDataRequest = () => {
  return axios.get(API_URL + '/user/data');
};

export const getEmployeesRequest = () => {
  return axios.get(API_URL + '/user/employees');
};

export const addNewCategoryFromUserRequest = (
  depId: number,
  userId: number
) => {
  return axios.put(API_URL + '/user/category', null, {
    params: { departamentId: depId, userId: userId },
  });
};

export const removeCategoryFromUserRequest = (
  depId: number,
  userId: number
) => {
  return axios.delete(API_URL + '/user/category', {
    params: { departamentId: depId, userId: userId },
  });
};

export const getStudentGroupRequest = (id: number | string) => {
  return axios.get(API_URL + '/user/student/group/', {
    params: {
      userId: id,
    },
  });
};

export const getStudentsRequest = () => {
  return axios.get(API_URL + '/user/students');
};

export const updateUserRoleRequest = (
  role: userRoleTypes,
  userId: number | string
) => {
  return axios.post(API_URL + '/user/change-role', {
    role,
    userId,
  });
};

export const getUsersFromPushMessagesRequest = () => {
  return axios.get(API_URL + '/push/users');
};

export const sendPushRequest = (
  title: string,
  body: string,
  options: { userIds?: number[]; isAll?: boolean }
) => {
  return axios.post(API_URL + '/push/push', {
    title,
    body,
    ...options,
  });
};
