import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import {
  Paper,
  Button,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Input from '../components/Input';
import { useFormik } from 'formik';
import { createUseStyles } from 'react-jss';
import {
  createCategoryAction,
  removeCategoryGroupAction,
  addCategoriesToGroupAction,
} from '../store/slices/categories';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import * as Yup from 'yup';
import { errorRequiredMessage } from '../utils';
import Icon from '../components/Icon';
import { RootState } from '../store';

const Categories: FC = () => {
  const dispatch = useDispatch();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [currentCategoryGroup, _setCurrentCategoryGroup] = useState(
    'add_new' as any
  );
  const [categoryGroupEnabled, setCategoryGroupEnabled] = useState({} as any);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(errorRequiredMessage),
    }),
    onSubmit: (values) => {
      createCategoryAction(dispatch)({ name: values.name });
      reset();
    },
  });
  const reset = () => formik.resetForm();
  const isCreateMode = currentCategoryGroup === 'add_new';
  let categories: any[] =
    useSelector((state: RootState) => state.categories.categories) || [];
  let categoryGroups =
    useSelector((state: RootState) => state.categories.groups) || [];
  const setCurrentCategoryGroup = (catId: any) => {
    _setCurrentCategoryGroup(catId);
    setCategoryGroupEnabled(
      (
        (categoryGroups.find((cg) => cg.id === catId) || {}).Categories || []
      ).reduce((com, c) => ({ ...com, [c]: true }), {}) || {}
    );
  };
  useEffect(() => {
    if (
      !isDataLoaded &&
      categoryGroups[0] &&
      !categoryGroups.find((cg) => cg.id === currentCategoryGroup)
    ) {
      setCurrentCategoryGroup(categoryGroups[0].id as any);
      setCategoryGroupEnabled(
        (categoryGroups[0].Categories || []).reduce(
          (com, c) => ({ ...com, [c]: true }),
          {}
        )
      );
      setIsDataLoaded(true);
    }
  }, [categoryGroups, currentCategoryGroup]);
  const loading = useSelector((state: any) => state.categories.loading);
  const classes = useStyles();
  const changeEnabledGroup = (id: number, state: boolean) => {
    setCategoryGroupEnabled({ ...categoryGroupEnabled, [id]: state });
  };
  const getCategoryGroupsEnabled: any = () =>
    Object.keys(categoryGroupEnabled).filter(
      (key) => categoryGroupEnabled[key]
    );

  const memoizeCategories = useMemo(
    () =>
      categories.map((e) => ({
        ...e,
        enabled: !!categoryGroupEnabled[e.id],
      })),
    [categories, categoryGroupEnabled]
  );
  return (
    <div>
      <Paper className={classes.header_controls}>
        <FormControl className={classes.select}>
          <InputLabel>Выберите группу</InputLabel>
          <Select
            value={currentCategoryGroup}
            onChange={(e: any) =>
              setCurrentCategoryGroup(e?.target?.value || '')
            }
          >
            {[...categoryGroups].map((cg: any, i: number) => (
              <MenuItem value={cg.id} key={i}>
                {cg.name}
              </MenuItem>
            ))}
            <MenuItem value="add_new">
              <div className={classes.select_add_btn}>
                <Icon>add</Icon>
                <span className={classes.select_add_btn_text}>Создать</span>
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      </Paper>

      {isCreateMode && (
        <Paper className={classes.form_card}>
          <Typography variant="h6">Создание категории</Typography>
          <form onSubmit={formik.handleSubmit}>
            <Input
              label="Название категории"
              type="text"
              meta={formik.getFieldMeta('name')}
              {...formik.getFieldProps('name')}
            />
            <div className={classes.form_card_footer}>
              {loading && <Loader className={classes.loader} />}
              <Button
                disabled={loading || !formik.isValid}
                type="submit"
                variant="contained"
                color="primary"
              >
                Создать
              </Button>
            </div>
          </form>
        </Paper>
      )}

      {!isCreateMode && (
        <>
          <div className={classes.table_header}>
            {!!loading && (
              <div className={classes.loader_wrapper}>
                <Loader />
                <Typography variant="body2" className={classes.loader_text}>
                  Загрузка...
                </Typography>
              </div>
            )}
            {!loading && (
              <>
                <Button
                  onClick={() =>
                    removeCategoryGroupAction(dispatch)(
                      currentCategoryGroup as any
                    )
                  }
                  disabled={loading}
                  startIcon={<Icon>delete</Icon>}
                >
                  Удалить группу
                </Button>
                <Button
                  onClick={() =>
                    addCategoriesToGroupAction(dispatch)(
                      getCategoryGroupsEnabled(),
                      currentCategoryGroup
                    )
                  }
                  disabled={loading}
                  startIcon={<Icon>save</Icon>}
                >
                  Сохранить изменения
                </Button>
              </>
            )}
          </div>
          <Paper className={classes.table_card}>
            <CategoryTable
              categories={memoizeCategories}
              loading={loading}
              changeEnabled={changeEnabledGroup}
            />
          </Paper>
        </>
      )}
    </div>
  );
};

export default Categories;

const CategoryTable: any = React.memo(
  ({ categories, loading, changeEnabled }: any) => {
    const _categries = (categories || [])
      .sort((ca: any, cb: any) =>
        (ca.category as string).localeCompare(cb.category)
      )
      .sort((ca: any, cb: any) => cb.enabled - ca.enabled);
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Доступно</TableCell>
            <TableCell>Название</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_categries.map((c: any) => (
            <TableRow key={c.id}>
              <TableCell>
                <Checkbox
                  onChange={(e) => changeEnabled(c.id, e.target.checked)}
                  disabled={loading}
                  checked={!!c.enabled}
                ></Checkbox>
              </TableCell>
              <TableCell>{c.category}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
);

const useStyles = createUseStyles({
  header_controls: {
    marginBottom: 24,
    padding: 8,
  },
  select: {
    minWidth: [200, '!important'],
  },
  select_add_btn: {
    display: 'flex',
    alignItems: 'center',
  },
  loader_wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  loader_text: {
    paddingLeft: 12,
  },
  select_add_btn_text: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingLeft: 8,
  },
  table_card: {
    maxWidth: 600,
    marginTop: 40,
  },
  table_header: {
    marginBottom: 24,
  },
  form_card: {
    padding: 12,
    maxWidth: 400,
    '& form': {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  form_card_footer: {
    paddingTop: 12,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  loader: {
    marginRight: 12,
  },
  table_controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: 32,
  },
  remove_loading: {},
});
