import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCategoriesRequest,
  createCategoryGroupRequest,
  removeCategoryGroupRequest,
  addCategoriesToGroupRequest,
} from '../api/categories';
import { openSnackbar } from './settings';

export interface CategoryModel {
  id: number;
  category: string;
  isuId: number | null;
  createdAt: string;
  updatedAt: string;
}

export interface CategoryGroupModel {
  id: number;
  name: string;
  Categories: number[];
  createdAt: string;
  updatedAt: string;
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    loading: false,
    groups: [] as CategoryGroupModel[],
    categories: [] as CategoryModel[],
  },
  reducers: {
    setData(state, action) {
      const { categories, groups } = action.payload;
      state.categories = categories || [];
      state.groups = groups || [];
    },
    addCategoryGroup(state, action: { payload: CategoryGroupModel }) {
      state.groups.push(action.payload);
    },
    setCategoryGroupsEnabled(state, action) {
      const currentCG = state.groups.find((cg) => cg.id === action.payload.id);
      if (currentCG) {
        currentCG.Categories = action.payload.ids || [];
      }
    },
    removeCategoryGroup(state, action: { payload: number }) {
      state.groups = state.groups.filter(
        (c: CategoryGroupModel) => c.id !== action.payload
      );
    },
    setLoadingCategory(state, action: { payload: boolean }) {
      state.loading = action.payload;
    },
  },
});

export const {
  addCategoryGroup,
  setData,
  setCategoryGroupsEnabled,
  removeCategoryGroup,
  setLoadingCategory,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;

export const getAllCategoriesAction = (dispatch: Function) => async () => {
  try {
    dispatch(setLoadingCategory(true));
    let data = (await getAllCategoriesRequest())?.data?.payload || [];
    dispatch(setData(data));
  } catch (err) {
    console.error('getAllCategoriesAction: ', err);
  } finally {
    dispatch(setLoadingCategory(false));
  }
};

export const createCategoryAction = (dispatch: Function) => async (model: {
  name: string;
}) => {
  try {
    dispatch(setLoadingCategory(true));
    const category: CategoryGroupModel = (
      await createCategoryGroupRequest(model.name)
    )?.data?.payload;
    dispatch(addCategoryGroup(category));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setLoadingCategory(false));
  }
};

export const removeCategoryGroupAction = (dispatch: Function) => async (
  id: number
) => {
  try {
    dispatch(setLoadingCategory(true));
    const { data } = await removeCategoryGroupRequest(id);
    dispatch(removeCategoryGroup(id));
    if (data?.type === 'success') {
      dispatch(
        openSnackbar({
          message: 'Группа успешно удалена',
          type: 'success',
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: 'Произошла ошибка при удалении группы',
          type: 'error',
        })
      );
    }
  } finally {
    dispatch(setLoadingCategory(false));
  }
};

export const addCategoriesToGroupAction = (dispatch: Function) => async (
  ids: number[],
  groupId: number
) => {
  try {
    dispatch(setLoadingCategory(true));
    const { data } = await addCategoriesToGroupRequest(ids, groupId);
    if (data.type === 'success') {
      dispatch(
        openSnackbar({
          message: 'Изменения категорий успешно схранены',
          type: 'success',
        })
      );
      dispatch(setCategoryGroupsEnabled({ id: groupId, ids }));
    } else {
      dispatch(
        openSnackbar({
          message: 'Произошла ошибка при изменении категорий',
          type: 'error',
        })
      );
    }
  } catch (err) {
    console.error(err);
    dispatch(
      openSnackbar({
        message: 'Произошла ошибка при изменении категорий',
        type: 'error',
      })
    );
  } finally {
    dispatch(setLoadingCategory(false));
  }
};

// export const removeCategoryAction = (dispatch: Function) => async (
//   id: number
// ) => {
//   try {
//     dispatch(setLoadingCategory(true));
//     const res = (await removeCategoryRequest(id))?.data;
//     dispatch(removeCategory(id));
//     console.log(res);
//   } catch (err) {
//     console.log(err);
//   } finally {
//     dispatch(setLoadingCategory(false));
//   }
// };
